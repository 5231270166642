import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-coin-analysis-rank',
  templateUrl: './coin-analysis-rank.component.html',
  styleUrls: ['./coin-analysis-rank.component.scss']
})
export class CoinAnalysisRankComponent implements OnInit {
   @Input() coin_id: string ;
   @Input() coin_name: string ;
   @Input() coin_symbol: string ;
   @Input() have_coin_logo: boolean ;
   @Input() coin_logo: string ;
   @Input() new_rank: number ;
   @Input() old_rank: number ;
   @Input() is_new: boolean ;
   @Input() changed: number ;
   @Input() changedP: number ;


  constructor() { }

  ngOnInit(): void {
  }

}

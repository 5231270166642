<div class="card-header border-0">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">{{title}}</span>
    <span class="text-muted mt-1 fw-bold fs-7">{{description}}</span>
  </h3>
</div>

<!--begin::Body-->
<div class="card-body py-3">
  <!--begin::Table container-->
  <div class="table-responsive">
    <!--begin::Table-->
    <table class="table align-middle gs-0 gy-3">
      <!--begin::Table head-->
      <thead>
      <tr class="fw-bolder text-muted bg-light">
        <th class="w-50px rounded-start"></th>
        <th class="min-w-150px"></th>
        <th class="min-w-75px">رتبه جدید</th>
        <th class="min-w-75px">رتبه قدیم</th>
        <th class="min-w-100px">پله صعود رتبه</th>
        <th class="min-w-100px rounded-end">درصد صعود رتبه
</th>
      </tr>
      </thead>
      <!--end::Table head-->
      <!--begin::Table body-->
      <tbody>
      <tr *ngFor="let row of rows">
        <td>
          <div class="symbol symbol-50px" data-bs-toggle="tooltip"  [title]="row.coin_name">
            <img *ngIf="row.coin_logo != ''" [src]="rootUrl + row.coin_logo" [alt]="row.coin_name" class="p-3"/>
            <span *ngIf="row.coin_logo == ''"
                  class="symbol-label bg-warning text-inverse-warning fw-bolder"
                  [style.font-size.rem]="3"
            >
          {{row.coin_name | slice:0:1}}
        </span>


          </div>
        </td>
        <td>
          <a [routerLink]="['/coins/'+row.coin_id+'/ranking']" class="text-dark fw-bolder text-hover-primary mb-1 fs-6">{{row.coin_name}}</a>
          <span class="text-muted fw-bold d-block fs-7">{{row.coin_symbol}}</span>
        </td>
        <td class="text-start text-success fw-bold">{{row.new_rank}}</td>
        <td class="text-start text-danger fw-bold">{{row.old_rank}}</td>
        <td class="text-start text-success fw-bolder" [style.direction]="'ltr'">+{{row.changed}}</td>
        <td class="text-start text-success fw-bolder" [style.direction]="'ltr'">+{{row.changedP | percent}}</td>
      </tr>
      </tbody>
      <!--end::Table body-->
    </table>
    <!--end::Table-->
  </div>
  <!--end::Table container-->
</div>
<!--begin::Body-->

import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CoinInfosService} from "../../api/services/coin-infos.service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {filter, first, map, pluck, switchMap, tap} from "rxjs/operators";
import {RankShotsService} from "../../api/services/rank-shots.service";
import {RankShot_} from "../../api/models/rank-shot-_";
import {RankShotD_} from "../../api/models/rank-shot-d_";
import {RankShotDsService} from "../../api/services/rank-shot-ds.service";
import {BinanceService} from "../../services/binance/binance.service";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {GeneralService} from "../../services/general/general.service";


@Component({
  selector: 'app-coins',
  templateUrl: './coins.component.html',
  styleUrls: ['./coins.component.scss']
})
export class CoinsComponent implements OnInit {
  coins$: Observable<RankShotD_[]>;
  id_last_rank: string;
  last_rank$: Observable<RankShot_>;
  last_rank: RankShot_;
  currentPage = 1
  countAllItems: number


  constructor(
    private coinInfosService: CoinInfosService,
    private rankShotsService: RankShotsService,
    private rankShotDsService: RankShotDsService,
    private title: Title,
    private translateService: TranslateService,
    private generalService:GeneralService,
  ) {
    generalService.Title({translate:true,titleString:'COINS.TITLE'})
  }

  ngOnInit(): void {
    this.last_rank$ = this.rankShotsService.rankShotsList({}).pipe(
      pluck('results'),
      map(value => {
        return value[0]
      }),
    )
    this.coins$ = this.get_coins_data()
  }


  onPageChange() {
    this.coins$ = this.get_coins_data()
  }

  get_coins_data(): Observable<RankShotD_[]> {
    return this.last_rank$.pipe(
      switchMap(lastRank =>
        this.rankShotDsService.rankShotDsList({rankShot: String(lastRank.id), page: this.currentPage}).pipe(
          map(value => {
            this.countAllItems = value.count
            return value
          }),
          pluck('results'),
        )
      )
    )
  }
}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SubscriptionPlan_ } from '../models/subscription-plan-_';
@Injectable({
  providedIn: 'root',
})
class PlansService extends __BaseService {
  static readonly plansListPath = '/plans/';
  static readonly plansCreatePath = '/plans/';
  static readonly plansReadPath = '/plans/{id}/';
  static readonly plansUpdatePath = '/plans/{id}/';
  static readonly plansPartialUpdatePath = '/plans/{id}/';
  static readonly plansDeletePath = '/plans/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Plans
   * @param params The `PlansService.PlansListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `name_en`:
   *
   * - `code`:
   */
  plansListResponse(params: PlansService.PlansListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<SubscriptionPlan_>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.nameEn != null) __params = __params.set('name_en', params.nameEn.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/plans/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<SubscriptionPlan_>}>;
      })
    );
  }
  /**
   * Plans
   * @param params The `PlansService.PlansListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `name_en`:
   *
   * - `code`:
   */
  plansList(params: PlansService.PlansListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<SubscriptionPlan_>}> {
    return this.plansListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<SubscriptionPlan_>})
    );
  }

  /**
   * Plans
   * @param data undefined
   */
  plansCreateResponse(data: SubscriptionPlan_): __Observable<__StrictHttpResponse<SubscriptionPlan_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/plans/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubscriptionPlan_>;
      })
    );
  }
  /**
   * Plans
   * @param data undefined
   */
  plansCreate(data: SubscriptionPlan_): __Observable<SubscriptionPlan_> {
    return this.plansCreateResponse(data).pipe(
      __map(_r => _r.body as SubscriptionPlan_)
    );
  }

  /**
   * Plans
   * @param id A UUID string identifying this subscription plan.
   */
  plansReadResponse(id: string): __Observable<__StrictHttpResponse<SubscriptionPlan_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/plans/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubscriptionPlan_>;
      })
    );
  }
  /**
   * Plans
   * @param id A UUID string identifying this subscription plan.
   */
  plansRead(id: string): __Observable<SubscriptionPlan_> {
    return this.plansReadResponse(id).pipe(
      __map(_r => _r.body as SubscriptionPlan_)
    );
  }

  /**
   * Plans
   * @param params The `PlansService.PlansUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this subscription plan.
   *
   * - `data`:
   */
  plansUpdateResponse(params: PlansService.PlansUpdateParams): __Observable<__StrictHttpResponse<SubscriptionPlan_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/plans/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubscriptionPlan_>;
      })
    );
  }
  /**
   * Plans
   * @param params The `PlansService.PlansUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this subscription plan.
   *
   * - `data`:
   */
  plansUpdate(params: PlansService.PlansUpdateParams): __Observable<SubscriptionPlan_> {
    return this.plansUpdateResponse(params).pipe(
      __map(_r => _r.body as SubscriptionPlan_)
    );
  }

  /**
   * Plans
   * @param params The `PlansService.PlansPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this subscription plan.
   *
   * - `data`:
   */
  plansPartialUpdateResponse(params: PlansService.PlansPartialUpdateParams): __Observable<__StrictHttpResponse<SubscriptionPlan_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/plans/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SubscriptionPlan_>;
      })
    );
  }
  /**
   * Plans
   * @param params The `PlansService.PlansPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this subscription plan.
   *
   * - `data`:
   */
  plansPartialUpdate(params: PlansService.PlansPartialUpdateParams): __Observable<SubscriptionPlan_> {
    return this.plansPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as SubscriptionPlan_)
    );
  }

  /**
   * Plans
   * @param id A UUID string identifying this subscription plan.
   */
  plansDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/plans/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Plans
   * @param id A UUID string identifying this subscription plan.
   */
  plansDelete(id: string): __Observable<null> {
    return this.plansDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PlansService {

  /**
   * Parameters for plansList
   */
  export interface PlansListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    nameEn?: string;
    code?: string;
  }

  /**
   * Parameters for plansUpdate
   */
  export interface PlansUpdateParams {

    /**
     * A UUID string identifying this subscription plan.
     */
    id: string;
    data: SubscriptionPlan_;
  }

  /**
   * Parameters for plansPartialUpdate
   */
  export interface PlansPartialUpdateParams {

    /**
     * A UUID string identifying this subscription plan.
     */
    id: string;
    data: SubscriptionPlan_;
  }
}

export { PlansService }

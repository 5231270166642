<div class="card mb-5 mb-xl-8">
  <!--begin::Header-->
  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <!--        <span class="card-label fw-bolder fs-3 mb-1">New Arrivals</span>-->
      <span class="text-muted mt-1 fw-bold fs-7">
          {{ 'COINS.LAST_UPDATE_RANKING' | translate }}:
          <span [style.direction]="'ltr'">{{(last_rank$ | async)?.created_time | date: 'yyyy/MM/dd HH:mm' }}</span>
        </span>
    </h3>
    <!--      <div class="card-toolbar">-->
    <!--        <a href="#" class="btn btn-sm btn-light-primary">-->
    <!--          &lt;!&ndash;begin::Svg Icon | path: icons/duotune/arrows/arr075.svg&ndash;&gt;-->
    <!--          <span class="svg-icon svg-icon-2">-->
    <!--  												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
    <!--  													<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"-->
    <!--                                  transform="rotate(-90 11.364 20.364)" fill="black"/>-->
    <!--  													<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"/>-->
    <!--  												</svg>-->
    <!--  											</span>-->
    <!--          &lt;!&ndash;end::Svg Icon&ndash;&gt;New Member</a>-->
    <!--      </div>-->
  </div>
  <!--end::Header-->
  <!--begin::Body-->
  <div class="card-body py-3">
    <!--begin::Table container-->
    <div class="table-responsive">
      <!--begin::Table-->
      <table class="table align-middle gs-0 gy-4">
        <!--begin::Table head-->
        <thead>
        <tr class="fw-bolder text-muted bg-light">
          <th class="ps-4 min-w-50px rounded-start">#</th>
          <th class="min-w-75px"></th>
          <th class="min-w-100px">{{'COINS.CATEGORY' | translate}}</th>
          <th class="min-w-125px">{{'COINS.PRICE' | translate}}</th>
          <th class="min-w-125px rounded-end">{{'COINS.MARKETCAP' | translate}}</th>
        </tr>
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody>
        <tr *ngFor='let coin of coins$ | async'>
          <td>
            <div class="d-flex justify-content-start flex-column ps-4">
              <span class="text-dark fw-bolder text-hover-primary mb-1 fs-6">{{coin.rank}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-50px me-5">
                <img *ngIf="coin.coin.logo" src="{{coin.coin.logo}}" class="" alt=""/>
                <i *ngIf="!coin.coin.logo" class="bi bi-coin" style="font-size: 4rem"></i>
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a [routerLink]="'/coins/'+[coin.coin.id]" class="text-dark fw-bolder text-hover-primary mb-1 fs-6">{{coin.coin.name}}</a>
                <span class="text-muted f w-bold text-muted d-block fs-7">{{coin.coin.symbol}}</span>
              </div>
            </div>
          </td>
          <td>
            <span class="badge badge-light-primary fs-7 fw-bold">{{coin.coin.category}}</span>
          </td>
          <td>
            <span class="text-dark fw-bolder d-block mb-1 fs-6">
              <app-price-live symbol="{{coin.coin.symbol}}"></app-price-live>
            </span>
            <!--            <span class="text-muted fw-bold text-muted d-block fs-7">دلار</span>-->
          </td>
          <td>
            <span class="text-dark fw-bolder  d-block mb-1 fs-6">
              <app-marketcap-live id="{{coin.coin.id}}"></app-marketcap-live>
            </span>
            <!--            <span class="text-muted fw-bold text-muted d-block fs-7">Rejected</span>-->
          </td>
        </tr>
        </tbody>
        <!--end::Table body-->
      </table>
      <!--end::Table-->
    </div>
    <!--end::Table container-->
  </div>
  <!--begin::Body-->
</div>
<!--end::Tables Widget 11-->
<!--<div *ngIf="countAllItems>0">-->
<!--<app-pagination-->
<!--  [currentPage]="currentPage"-->
<!--  [pageItems]="24"-->
<!--  [countAllItems]="countAllItems"-->
<!--&gt;</app-pagination>-->
<!--</div>-->
<ngb-pagination
  [collectionSize]="countAllItems"
  [(page)]="currentPage"
  [pageSize]="24"
  [maxSize]="5"
  [boundaryLinks]="true"
  (pageChange)="onPageChange()"
  aria-label="Default pagination"></ngb-pagination>


<!--begin::Pricing card-->
<div id="kt_pricing" class="card">
  <!--begin::Card body-->
  <div class="card-body p-lg-17">
    <!--begin::Plans-->
    <div class="d-flex flex-column">
      <!--begin::Heading-->
      <div class="mb-13 text-center">
        <span id="title_in_header" class="btn-label visually-hidden">{{'SUBSCRIBE.MENU_TITLE' | translate}}</span>
        <h1 class="fs-2hx fw-bolder mb-5">{{'SUBSCRIBE.CHOOSE_YOUR_PLAN' | translate}}</h1>
        <div class="text-gray-400 fw-bold fs-5">
          {{'SUBSCRIBE.CHOOSE_YOUR_PLAN_DESCRIPTION' | translate}}
          <a class="link-primary fw-bolder"
             href="mailto:info@hashtcoin.com"> {{'CONTACT.MENU_TITLE' | translate}} </a>
        </div>
      </div>
      <!--end::Heading-->
      <!--begin::Nav group-->
      <div class="nav-group nav-group-outline mx-auto mb-15">
        <button class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2"
                (click)="onChangeDuration('month')" [ngClass]="duration=='month'?'active':'' ">
          {{'SUBSCRIBE.MONTHLY' | translate}}
        </button>
        <button class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"
                (click)="onChangeDuration('year')" [ngClass]="duration=='year'?'active':''">
          {{'SUBSCRIBE.ANNUAL' | translate}}
        </button>
      </div>
      <!--end::Nav group-->
      <!--begin::Row-->
      <div class="row g-10">
        <!--begin::Col-->
        <div class="col-xl-4">
          <div class="d-flex h-100 align-items-center">
            <!--begin::Option-->
            <div class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
              <!--begin::Heading-->
              <div class="mb-7 text-center">
                <!--begin::Title-->
                <h1 class="text-dark mb-5 fw-boldest fs-2hx">{{'SUBSCRIBE.TRIAL' | translate}}</h1>
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-gray-400 fw-bold mb-5" [style.max-width.px]="200">
                  {{'SUBSCRIBE.TRIAL_DES' | translate}}
                </div>
                <!--end::Description-->
                <!--begin::Price-->
                <div class="text-center">
                  <span class="fs-3x fw-bolder text-success">{{'SUBSCRIBE.FREE' | translate}} </span>
                  <span class="fs-4 mb-2 text-primary">{{'SUBSCRIBE.FOR_ALWAYS' | translate}}</span>

                </div>
                <!--end::Price-->
              </div>
              <!--end::Heading-->
              <!--begin::Features-->
              <div class="w-100 mb-10">
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.01' | translate}}
                    :
                  <span class="fs-3">3 {{'GENERAL.DAY' | translate}}  {{'GENERAL.AGO' | translate}}</span>
                  </span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.02' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.03' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.04' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.05' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.06' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.07' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.08' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.09' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Features-->
              <!--begin::Select-->
              <a *ngIf="!isAuthed"
                 [routerLink]="'/auth/login'"
                 [queryParams]="{'returnUrl': 'subscribe'}"
                 class="btn btn-sm btn-primary"
              >{{ 'TOPBAR.LOGIN' | translate }}</a>
              <!--end::Select-->
            </div>
            <!--end::Option-->
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xl-4">
          <div class="d-flex h-100 align-items-center">
            <!--begin::Option-->
            <div class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
              <!--begin::Heading-->
              <div class="mb-7 text-center">
                <!--begin::Title-->
                <h1 class="text-dark mb-5 fw-boldest fs-2hx">{{'SUBSCRIBE.PRO' | translate}}</h1>
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-gray-400 fw-bold mb-5" [style.max-width.px]="200">
                  {{'SUBSCRIBE.PRO_DES' | translate}}
                </div>
                <!--end::Description-->
                <!--begin::Price-->
                <div class="text-center">
                  <span class="fs-2  text-dark">
                    <s>{{duration == 'month' ? plans.pro.month_fee : plans.pro.year_fee }}</s>
                  </span>
                  <span class="fs-3x fw-bolder text-success">
                    {{duration == 'month' ? plans.pro.month_feeD : plans.pro.year_feeD }}
                  </span>
                  <span class="mb-2 text-primary">{{'GENERAL.DOLLAR'|translate}}</span>
                  <span class="fs-7 fw-bold opacity-50">/
                    <span data-kt-element="period">
                      {{duration == 'month' ? ('GENERAL.MONTH' | translate) : ('GENERAL.YEAR' | translate) }}
                    </span>
                  </span>
                </div>
                <!--end::Price-->
              </div>
              <!--end::Heading-->
              <!--begin::Features-->
              <div class="w-100 mb-10">
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.01' | translate}}
                    :
                  <span class="fs-3">1 {{'GENERAL.MONTH' | translate}} {{'GENERAL.AGO' | translate}}</span>
                  </span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.02' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.03' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.04' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.05' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.06' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.07' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.08' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center">
                  <span class="fw-bold fs-6 text-gray-400 flex-grow-1">{{'SUBSCRIBE.FEATURES.09' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="black"/>
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Features-->
              <!--begin::Select-->
              <!--              <a class="btn btn-sm btn-primary" (click)="onPayment()">-->
              <!--                <span>{{'SUBSCRIBE.PAYMENT' | translate}}</span>-->
              <!--              </a>-->
              <button class="btn btn-sm btn-primary w-75px"  type="button" *ngIf="isAuthed"
                      (click)="onPayment()" [disabled]="paymenting">
                <span *ngIf="!paymenting">{{'SUBSCRIBE.PAYMENT' | translate}}</span>
                <span *ngIf="paymenting" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
              </button>
              <a *ngIf="!isAuthed"
                 [routerLink]="'/auth/login'"
                 [queryParams]="{'returnUrl': 'subscribe'}"
                 class="btn btn-sm btn-primary"
              >{{ 'TOPBAR.LOGIN' | translate }}</a>
              <!--end::Select-->
            </div>
            <!--end::Option-->
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xl-4">
          <div class="d-flex h-100 align-items-center">
            <!--begin::Option-->
            <div class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
              <!--begin::Heading-->
              <div class="mb-7 text-center">
                <!--begin::Title-->
                <h1 class="text-dark mb-5 fw-boldest fs-2hx">{{'SUBSCRIBE.PRO_PLUS' | translate}}</h1>
                <!--end::Title-->
                <!--begin::Description-->
                <div class="text-gray-400 fw-bold mb-5" [style.max-width.px]="200">
                  {{'SUBSCRIBE.PRO_PLUS_DES' | translate}}
                </div>
                <!--end::Description-->
                <!--begin::Price-->
                <div class="text-center">
                  <span class="fs-2  text-dark">
                    <s>{{duration == 'month' ? plans.proPlus.month_fee : plans.proPlus.year_fee }}</s>
                  </span>
                  <span class="fs-3x fw-bolder text-success">
                    {{duration == 'month' ? plans.proPlus.month_feeD : plans.proPlus.year_feeD }}
                  </span>
                  <span class="mb-2 text-primary">{{'GENERAL.DOLLAR'|translate}}</span>
                  <span class="fs-7 fw-bold opacity-50">/
                    <span data-kt-element="period">
                      {{duration == 'month' ? ('GENERAL.MONTH' | translate) : ('GENERAL.YEAR' | translate) }}
                    </span>
                  </span>
                </div>
                <!--end::Price-->
              </div>
              <!--end::Heading-->
              <!--begin::Features-->
              <div class="w-100 mb-10">
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.01' | translate}}
                    :
                  <span class="fs-3">{{'GENERAL.UNLIMITED' | translate}}</span>
                  </span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.02' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.03' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.04' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.05' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.06' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.07' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.08' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div class="d-flex align-items-center">
                  <span
                    class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 ps-0">{{'SUBSCRIBE.FEATURES.09' | translate}}</span>
                  <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                      <path
                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                        fill="black"/>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Features-->
              <!--begin::Select-->
              <a class="btn btn-sm btn-primary disabled">{{'SUBSCRIBE.COMING_SOON' | translate}}</a>
              <!--end::Select-->
            </div>
            <!--end::Option-->
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Plans-->
  </div>
  <!--end::Card body-->
</div>
<!--end::Pricing card-->

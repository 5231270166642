import { Component, OnInit } from '@angular/core';
import {GeneralService} from "../../../services/general/general.service";

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {

  constructor(
    private generalService:GeneralService,
  ) {
    generalService.Title({translate:true,titleString:'SUBSCRIBE.SUCCESS_PAYMENT.TITLE'})
  }

  ngOnInit(): void {
  }

}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CoinURL_ } from '../models/coin-url_';
@Injectable({
  providedIn: 'root',
})
class CoinUrlsService extends __BaseService {
  static readonly coinUrlsListPath = '/coin-urls/';
  static readonly coinUrlsCreatePath = '/coin-urls/';
  static readonly coinUrlsReadPath = '/coin-urls/{id}/';
  static readonly coinUrlsUpdatePath = '/coin-urls/{id}/';
  static readonly coinUrlsPartialUpdatePath = '/coin-urls/{id}/';
  static readonly coinUrlsDeletePath = '/coin-urls/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Coin URLs
   * @param params The `CoinUrlsService.CoinUrlsListParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `group`:
   *
   * - `coin`:
   */
  coinUrlsListResponse(params: CoinUrlsService.CoinUrlsListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<CoinURL_>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.url != null) __params = __params.set('url', params.url.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.group != null) __params = __params.set('group', params.group.toString());
    if (params.coin != null) __params = __params.set('coin', params.coin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coin-urls/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<CoinURL_>}>;
      })
    );
  }
  /**
   * Coin URLs
   * @param params The `CoinUrlsService.CoinUrlsListParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `group`:
   *
   * - `coin`:
   */
  coinUrlsList(params: CoinUrlsService.CoinUrlsListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<CoinURL_>}> {
    return this.coinUrlsListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<CoinURL_>})
    );
  }

  /**
   * Coin URLs
   * @param data undefined
   */
  coinUrlsCreateResponse(data: CoinURL_): __Observable<__StrictHttpResponse<CoinURL_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/coin-urls/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinURL_>;
      })
    );
  }
  /**
   * Coin URLs
   * @param data undefined
   */
  coinUrlsCreate(data: CoinURL_): __Observable<CoinURL_> {
    return this.coinUrlsCreateResponse(data).pipe(
      __map(_r => _r.body as CoinURL_)
    );
  }

  /**
   * Coin URLs
   * @param id A unique integer value identifying this coin url.
   */
  coinUrlsReadResponse(id: number): __Observable<__StrictHttpResponse<CoinURL_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coin-urls/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinURL_>;
      })
    );
  }
  /**
   * Coin URLs
   * @param id A unique integer value identifying this coin url.
   */
  coinUrlsRead(id: number): __Observable<CoinURL_> {
    return this.coinUrlsReadResponse(id).pipe(
      __map(_r => _r.body as CoinURL_)
    );
  }

  /**
   * Coin URLs
   * @param params The `CoinUrlsService.CoinUrlsUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this coin url.
   *
   * - `data`:
   */
  coinUrlsUpdateResponse(params: CoinUrlsService.CoinUrlsUpdateParams): __Observable<__StrictHttpResponse<CoinURL_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/coin-urls/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinURL_>;
      })
    );
  }
  /**
   * Coin URLs
   * @param params The `CoinUrlsService.CoinUrlsUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this coin url.
   *
   * - `data`:
   */
  coinUrlsUpdate(params: CoinUrlsService.CoinUrlsUpdateParams): __Observable<CoinURL_> {
    return this.coinUrlsUpdateResponse(params).pipe(
      __map(_r => _r.body as CoinURL_)
    );
  }

  /**
   * Coin URLs
   * @param params The `CoinUrlsService.CoinUrlsPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this coin url.
   *
   * - `data`:
   */
  coinUrlsPartialUpdateResponse(params: CoinUrlsService.CoinUrlsPartialUpdateParams): __Observable<__StrictHttpResponse<CoinURL_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/coin-urls/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinURL_>;
      })
    );
  }
  /**
   * Coin URLs
   * @param params The `CoinUrlsService.CoinUrlsPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this coin url.
   *
   * - `data`:
   */
  coinUrlsPartialUpdate(params: CoinUrlsService.CoinUrlsPartialUpdateParams): __Observable<CoinURL_> {
    return this.coinUrlsPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as CoinURL_)
    );
  }

  /**
   * Coin URLs
   * @param id A unique integer value identifying this coin url.
   */
  coinUrlsDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/coin-urls/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Coin URLs
   * @param id A unique integer value identifying this coin url.
   */
  coinUrlsDelete(id: number): __Observable<null> {
    return this.coinUrlsDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CoinUrlsService {

  /**
   * Parameters for coinUrlsList
   */
  export interface CoinUrlsListParams {
    url?: string;

    /**
     * A search term.
     */
    search?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    group?: string;
    coin?: string;
  }

  /**
   * Parameters for coinUrlsUpdate
   */
  export interface CoinUrlsUpdateParams {

    /**
     * A unique integer value identifying this coin url.
     */
    id: number;
    data: CoinURL_;
  }

  /**
   * Parameters for coinUrlsPartialUpdate
   */
  export interface CoinUrlsPartialUpdateParams {

    /**
     * A unique integer value identifying this coin url.
     */
    id: number;
    data: CoinURL_;
  }
}

export { CoinUrlsService }

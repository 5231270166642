import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tv-chart',
  templateUrl: './tv-chart.component.html',
  styleUrls: ['./tv-chart.component.scss']
})
export class TvChartComponent implements OnInit {
  @Input() symbol: string;

  constructor() {
  }

  ngOnInit(): void {
    let lang = localStorage.getItem('language');
    if (lang == 'fa') {
      lang = 'fa_IR'
    } else if (lang == 'ar') {
      lang = 'ar_AE'
    } else {
      lang = 'en'
    }
    new TradingView.widget({
      "container_id": "myWidgetContainer",
      "autosize": true,
      "symbol": this.symbol,
      "interval": "360",
      "timezone": "Etc/UTC",
      "theme": "light",
      "style": "1",
      "locale": lang,
      "toolbar_bg": "#f1f3f6",
      "enable_publishing": false,
      "withdateranges": true,
      "hide_side_toolbar": false,
      "allow_symbol_change": true,
      "details": true,
      "hotlist": true,
      "calendar": true,
      "studies": [
        "MASimple@tv-basicstudies"
      ],
    });
  }

}

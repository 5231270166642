<a
  class="card border border-2 border-gray-300 border-hover"
  [routerLink]="['/coins/'+coin_id+'/ranking']"
>
  <div class="card-header border-0 pt-9">
    <div class="card-title m-0">
      <div class="symbol symbol-100px w-100px bg-light">
        <img *ngIf="have_coin_logo" [src]="coin_logo" [alt]="coin_name" class="p-3"/>
        <span *ngIf="!have_coin_logo"
              class="symbol-label bg-warning text-inverse-warning fw-bolder"
              [style.font-size.rem]="3"
        >
          {{coin_name | slice:0:1}}
        </span>
      </div>
      <div class="mx-5">
        <div class="fs-3 fw-bolder text-dark">{{ coin_name }}</div>
        <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7">{{ coin_symbol }}</p>
      </div>
    </div>
  </div>

  <div class="card-body p-9">

    <div class="d-flex flex-wrap mb-5">
      <div
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          mx-auto
        "
      >
        <h1 class="fw-bolder text-success">{{ new_rank }}</h1>
        <div class="fw-bold text-gray-400">{{'RANK_ANALYSIS.NEW_RANK'|translate}}</div>
      </div>

      <div
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          mx-auto
        "
      >
        <h1 class=" fw-bolder text-danger">{{ old_rank }}</h1>
        <div class="fw-bold text-gray-400">{{'RANK_ANALYSIS.OLD_RANK'|translate}}</div>
      </div>
    </div>

    <div class="d-flex flex-wrap mb-5">
      <div
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          mx-auto
        "
      >
        <h1 class="fw-bolder text-success">+{{ changed }}</h1>
        <div class="fw-bold text-gray-400">{{'RANK_ANALYSIS.CHANGEPLUS'|translate}}</div>
      </div>

      <div
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          mx-auto
        "
      >
        <h1 class=" fw-bolder text-success">{{ changedP | percent }}</h1>
        <div class="fw-bold text-gray-400">{{'RANK_ANALYSIS.CHANGEPPLUS'|translate}}</div>
      </div>
    </div>

<!--    <div class="h-4px w-100 bg-light mb-5" ngbTooltip="This project completed">-->
<!--      <div-->
<!--        class="rounded h-4px"-->
<!--        [ngClass]="'bg-' + 'statusColor'"-->
<!--        role="progressbar"-->
<!--        [style.width]="'progress' + '%'"-->
<!--      ></div>-->
<!--    </div>-->

  </div>
</a>

import {Injectable, OnDestroy} from '@angular/core';
import {Observable, BehaviorSubject, of, Subscription, from} from 'rxjs';
import {map, catchError, switchMap, finalize, pluck, first, take, tap} from 'rxjs/operators';
import {UserModel} from '../models/user.model';
import {AuthModel} from '../models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {User} from "../../../api/models/user";
import {AuthService as AuthServiceMe} from "../../../api/services/auth.service";
import {UsersService} from "../../../api/services/users.service";
import {jwtOptionsFactory} from "../../../app.module";
import {SendEmailReset} from "../../../api/models/send-email-reset";
import {UserCreatePasswordRetype} from "../../../api/models/user-create-password-retype";

export type UserType = User | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }


  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private authServiceMe: AuthServiceMe,
    private usersService: UsersService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authServiceMe.authJwtCreateCreate({email, password}).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        jwtOptionsFactory();
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }

  loginOAuth(jwtData: AuthModel): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return of(jwtData).pipe(
      map((auth: AuthModel) => {
        const a:AuthModel={
          access:auth['access'],
          refresh:auth['refresh'],
        }
        const result = this.setAuthFromLocalStorage(a);
        jwtOptionsFactory();
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }


  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    // this.router.navigate(['/auth/login'], {
    //   queryParams: {},
    // });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.access) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.usersService.usersMeRead({}).pipe(
      // map((user: UserType) => {
      //   if (user) {
      //     this.currentUserSubject.next(user);
      //   } else {
      //     this.logout();
      //   }
      //   return user;
      // }),
      map(value => {
        // @ts-ignore
        const u: User = value

        if (u) {
          this.currentUserSubject.next(u);
          localStorage.setItem('language', u.language! )
        } else {
          this.logout();
        }
        return u;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserCreatePasswordRetype): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.usersService.usersCreate({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      password: user.password,
      re_password: user.re_password,
      language:user.language
    }).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(err)
        // return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
    // return this.authHttpService.createUser(user).pipe(
    //   map(() => {
    //     this.isLoadingSubject.next(false);
    //   }),
    //   switchMap(() => this.login(user.email, user.password)),
    //   catchError((err) => {
    //     console.error('err', err);
    //     return of(undefined);
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  forgotPassword(email: string): Observable<SendEmailReset> {
    this.isLoadingSubject.next(true);
    return this.usersService
      .usersResetPassword({email: email})
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.access) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

<div class="card">
  <!--begin::Body-->
  <div class="card-body p-lg-15">
    <!--begin::Classic content-->
    <div class="mb-13">
      <!--begin::Intro-->
      <div class="mb-15">
        <!--begin::Title-->
<!--        <h4 class="fs-2x text-gray-800 w-bolder mb-6">-->
<!--          {{'HELP.MENU_TITLE' | translate}}-->
<!--        </h4>-->
        <!--end::Title-->
        <!--begin::Text-->
        <p class="fw-bold fs-4 text-gray-600 mb-2">
          {{'HELP.DESCRIPTION' | translate}}
        </p>
        <!--end::Text-->
      </div>
      <!--end::Intro-->
      <!--begin::Row-->
      <div class="row mb-12">
        <!--begin::Col-->
        <div class="col-xl-6 pe-md-10 mb-10 mb-xl-0">
          <!--begin::Title-->
          <h2 class="text-gray-800 fw-bolder mb-4">
            {{'HELP.PART1.TITLE' | translate}}
          </h2>
          <!--end::Title-->
          <!--begin::Accordion-->
          <app-collapse ask="{{'HELP.PART1.QA01.Q' | translate}}"  [isCollapsed]="true">
            {{'HELP.PART1.QA01.A' | translate}}
          </app-collapse>
          <app-collapse ask="{{'HELP.PART1.QA02.Q' | translate}}"  [isCollapsed]="true">
            {{'HELP.PART1.QA02.A' | translate}}
          </app-collapse>
          <app-collapse ask="{{'HELP.PART1.QA03.Q' | translate}}"  [isCollapsed]="true">
            {{'HELP.PART1.QA03.A' | translate}}
          </app-collapse>
          <!--end::Accordion-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xl-6 ps-xl-10">
          <!--begin::Title-->
          <h2 class="text-gray-800 fw-bolder mb-4">
            {{'HELP.PART2.TITLE' | translate}}
          </h2>
          <!--end::Title-->
          <!--begin::Accordion-->
          <app-collapse ask="{{'HELP.PART2.QA01.Q' | translate}}"  [isCollapsed]="true">
            {{'HELP.PART2.QA01.A' | translate}}
          </app-collapse>
          <app-collapse ask="{{'HELP.PART2.QA02.Q' | translate}}"  [isCollapsed]="true">
            {{'HELP.PART2.QA02.A' | translate}}
          </app-collapse>
          <!--end::Accordion-->
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Classic content-->
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-coin-analysis-table',
  templateUrl: './coin-analysis-table.component.html',
  styleUrls: ['./coin-analysis-table.component.scss']
})
export class CoinAnalysisTableComponent implements OnInit {
  @Input() title: string ;
  @Input() description: string ;
  rootUrl:string=environment.mediaUrl;
  @Input() rows: any ;

  constructor() { }

  ngOnInit(): void {}

}

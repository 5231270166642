import {NgModule, APP_INITIALIZER, forwardRef, Provider} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth/services/auth.service';
import {environment} from 'src/environments/environment';
// #fake-start#
import {FakeAPIService} from './_fake/fake-api.service';
import {JWT_OPTIONS, JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {ApiInterceptor} from "./api/noop-interceptor";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CoinsComponent } from './pages/coins/coins.component';
import { PriceLiveComponent } from './shared/price-live/price-live.component';
import { MarketcapLiveComponent } from './shared/marketcap-live/marketcap-live.component';
import { LastRankAnalysisComponent } from './pages/rank-analysis/last-rank-analysis/last-rank-analysis.component';
import {NgApexchartsModule} from "ng-apexcharts";
import { CoinAnalysisRankComponent } from './shared/coin-analysis-rank/coin-analysis-rank.component';
import { CoinAnalysisTableComponent } from './shared/coin-analysis-table/coin-analysis-table.component';
import { CoinComponent } from './pages/coins/coin/coin.component';
import { UrlsComponent } from './shared/urls/urls.component';
import { OverviewComponent } from './pages/coins/coin/overview/overview.component';
import { RankingComponent } from './pages/coins/coin/ranking/ranking.component';
import { TvChartComponent } from './shared/tv-chart/tv-chart.component';
import { CompareSubscribeComponent } from './pages/compare-subscribe/compare-subscribe.component';
import {GravatarModule} from "ngx-gravatar";
import { SuccessPaymentComponent } from './pages/payments/success-payment/success-payment.component';
import { ErrorPaymentComponent } from './pages/payments/error-payment/error-payment.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import {CountUpModule} from "ngx-countup";
import {SwiperModule} from "swiper/angular";
import { ToastComponent } from './services/toast/toast/toast.component';
import { PythonComponent } from './pages/programming/python/python.component';
import { TradingviewComponent } from './pages/programming/tradingview/tradingview.component';
import {InlineSVGModule} from "ng-inline-svg-2";
import { CollapseComponent } from './shared/collapse/collapse.component';
import { HelpComponent } from './pages/help/help.component';
import { WalletAddressesComponent } from './pages/wallet-addresses/wallet-addresses.component';
import { WalletComponent } from './shared/wallet/wallet.component';

// #fake-end#

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      // @ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function jwtOptionsFactory() {
  let jwt: string = localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`)!;
  if (jwt) {
    // console.log(jwt)
    const jwtJson = JSON.parse(jwt);
    const access = jwtJson.access
    return {
      tokenGetter: () => access,
      allowedDomains: ['127.0.0.1:8000', 'https://api.hashtcoin.com', 'api.hashtcoin.com', 'http://api.hashtcoin.com'],
    }
  } else {
    return {
      tokenGetter: () => undefined,
      allowedDomains: ['127.0.0.1:8000', 'https://api.hashtcoin.com', 'api.hashtcoin.com', 'http://api.hashtcoin.com'],
    }
  }
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

@NgModule({
  declarations: [AppComponent, DashboardComponent, CoinsComponent, PriceLiveComponent, MarketcapLiveComponent, LastRankAnalysisComponent, CoinAnalysisRankComponent, CoinAnalysisTableComponent, CoinComponent, UrlsComponent, OverviewComponent, RankingComponent, TvChartComponent, CompareSubscribeComponent, SuccessPaymentComponent, ErrorPaymentComponent, AboutUsComponent, ToastComponent, PythonComponent, TradingviewComponent, CollapseComponent, HelpComponent, WalletAddressesComponent, WalletComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    SweetAlert2Module.forRoot(),
    GravatarModule,
    CountUpModule,
    SwiperModule,

    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgApexchartsModule,
    // JwtModule.forRoot({
    //   jwtOptionsProvider: {
    //     provide: JWT_OPTIONS,
    //     useFactory: jwtOptionsFactory,
    //     // deps: [Storage],
    //   },
    //
    // }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    Title,
    // JwtHelperService,
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

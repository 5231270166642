import {Component, Input, OnInit} from '@angular/core';
import {CoinInfosService} from "../../api/services/coin-infos.service";
import {Observable} from "rxjs";
import {map, pluck} from "rxjs/operators";

@Component({
  selector: 'app-marketcap-live',
  templateUrl: './marketcap-live.component.html',
  styleUrls: ['./marketcap-live.component.scss']
})
export class MarketcapLiveComponent implements OnInit {
  @Input() id = ''
  marketcap$: Observable<any>
  loading = true

  constructor(
    private coinInfosService:CoinInfosService
  ) { }

  ngOnInit(): void {
    this.get_marketcap()
  }

  get_marketcap(){
    this.loading = true
    this.marketcap$ =  this.coinInfosService.coinInfosGetLastMarcetcapRead(this.id).pipe(
      pluck('last_marketcap'),
      map(value => {
        this.loading = false
        if (value) {
          return value
        } else {
          return '-'
        }
      })
    )

  }

}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RankShotD_ } from '../models/rank-shot-d_';
@Injectable({
  providedIn: 'root',
})
class RankShotDsService extends __BaseService {
  static readonly rankShotDsListPath = '/rank-shot-ds/';
  static readonly rankShotDsCreatePath = '/rank-shot-ds/';
  static readonly rankShotDsReadPath = '/rank-shot-ds/{id}/';
  static readonly rankShotDsUpdatePath = '/rank-shot-ds/{id}/';
  static readonly rankShotDsPartialUpdatePath = '/rank-shot-ds/{id}/';
  static readonly rankShotDsDeletePath = '/rank-shot-ds/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Rank Shot Ds
   * @param params The `RankShotDsService.RankShotDsListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `rankShot`:
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `coin`:
   */
  rankShotDsListResponse(params: RankShotDsService.RankShotDsListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<RankShotD_>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.rankShot != null) __params = __params.set('rankShot', params.rankShot.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.coin != null) __params = __params.set('coin', params.coin.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rank-shot-ds/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<RankShotD_>}>;
      })
    );
  }
  /**
   * Rank Shot Ds
   * @param params The `RankShotDsService.RankShotDsListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `rankShot`:
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `coin`:
   */
  rankShotDsList(params: RankShotDsService.RankShotDsListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<RankShotD_>}> {
    return this.rankShotDsListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<RankShotD_>})
    );
  }

  /**
   * Rank Shot Ds
   * @param data undefined
   */
  rankShotDsCreateResponse(data: RankShotD_): __Observable<__StrictHttpResponse<RankShotD_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rank-shot-ds/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShotD_>;
      })
    );
  }
  /**
   * Rank Shot Ds
   * @param data undefined
   */
  rankShotDsCreate(data: RankShotD_): __Observable<RankShotD_> {
    return this.rankShotDsCreateResponse(data).pipe(
      __map(_r => _r.body as RankShotD_)
    );
  }

  /**
   * Rank Shot Ds
   * @param id A unique integer value identifying this rank shot d.
   */
  rankShotDsReadResponse(id: number): __Observable<__StrictHttpResponse<RankShotD_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rank-shot-ds/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShotD_>;
      })
    );
  }
  /**
   * Rank Shot Ds
   * @param id A unique integer value identifying this rank shot d.
   */
  rankShotDsRead(id: number): __Observable<RankShotD_> {
    return this.rankShotDsReadResponse(id).pipe(
      __map(_r => _r.body as RankShotD_)
    );
  }

  /**
   * Rank Shot Ds
   * @param params The `RankShotDsService.RankShotDsUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot d.
   *
   * - `data`:
   */
  rankShotDsUpdateResponse(params: RankShotDsService.RankShotDsUpdateParams): __Observable<__StrictHttpResponse<RankShotD_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rank-shot-ds/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShotD_>;
      })
    );
  }
  /**
   * Rank Shot Ds
   * @param params The `RankShotDsService.RankShotDsUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot d.
   *
   * - `data`:
   */
  rankShotDsUpdate(params: RankShotDsService.RankShotDsUpdateParams): __Observable<RankShotD_> {
    return this.rankShotDsUpdateResponse(params).pipe(
      __map(_r => _r.body as RankShotD_)
    );
  }

  /**
   * Rank Shot Ds
   * @param params The `RankShotDsService.RankShotDsPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot d.
   *
   * - `data`:
   */
  rankShotDsPartialUpdateResponse(params: RankShotDsService.RankShotDsPartialUpdateParams): __Observable<__StrictHttpResponse<RankShotD_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/rank-shot-ds/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShotD_>;
      })
    );
  }
  /**
   * Rank Shot Ds
   * @param params The `RankShotDsService.RankShotDsPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot d.
   *
   * - `data`:
   */
  rankShotDsPartialUpdate(params: RankShotDsService.RankShotDsPartialUpdateParams): __Observable<RankShotD_> {
    return this.rankShotDsPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as RankShotD_)
    );
  }

  /**
   * Rank Shot Ds
   * @param id A unique integer value identifying this rank shot d.
   */
  rankShotDsDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rank-shot-ds/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Rank Shot Ds
   * @param id A unique integer value identifying this rank shot d.
   */
  rankShotDsDelete(id: number): __Observable<null> {
    return this.rankShotDsDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RankShotDsService {

  /**
   * Parameters for rankShotDsList
   */
  export interface RankShotDsListParams {

    /**
     * A search term.
     */
    search?: string;
    rankShot?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    coin?: string;
  }

  /**
   * Parameters for rankShotDsUpdate
   */
  export interface RankShotDsUpdateParams {

    /**
     * A unique integer value identifying this rank shot d.
     */
    id: number;
    data: RankShotD_;
  }

  /**
   * Parameters for rankShotDsPartialUpdate
   */
  export interface RankShotDsPartialUpdateParams {

    /**
     * A unique integer value identifying this rank shot d.
     */
    id: number;
    data: RankShotD_;
  }
}

export { RankShotDsService }

import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {TranslationService} from './modules/i18n';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as arLang} from './modules/i18n/vocabs/ar';
import {locale as faLang} from './modules/i18n/vocabs/fa';
import {locale as chLang} from './modules/i18n/vocabs/ch';
import {locale as esLang} from './modules/i18n/vocabs/es';
import {locale as jpLang} from './modules/i18n/vocabs/jp';
import {locale as deLang} from './modules/i18n/vocabs/de';
import {locale as frLang} from './modules/i18n/vocabs/fr';
import {DOCUMENT} from "@angular/common";
import {fromFetch} from "rxjs/fetch";
import {catchError, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {toJSON} from "./_metronic/kt/_utils";
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      faLang,
      arLang,
      // chLang,
      // esLang,
      // jpLang,
      // deLang,
      // frLang
    );
  }

  ngOnInit() {
    // todo: Add google analytics

    let lang = localStorage.getItem('language');
    if (!lang) {
      // const response = JSON.parse(this.httpGet('http://ip-api.com/json/'))
      // // https://en.wikipedia.org/wiki/List_of_countries_where_Arabic_is_an_official_language
      // const arabicCountries = ['DZ', 'BH', 'TD', 'KM', 'DJ', 'EG', 'IQ', 'JO', 'KW', 'LB', 'LY', 'MR', 'MA', 'OM', 'PS',
      //   'QA', 'SA', 'SO', 'SD', 'SY', 'TN', 'AE', 'YE']
      //
      // if (response.countryCode == 'IR') {
      //   localStorage.setItem('language', 'fa')
      // } else if (arabicCountries.includes(response.countryCode)) {
      //   localStorage.setItem('language', 'ar')
      // } else {
      //   localStorage.setItem('language', 'en')
      // }

      localStorage.setItem('language', 'en')
    }

    this.translationService.setDirHTML()
  }

  httpGet(theUrl: string) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, false); // false for synchronous request
    xmlHttp.send(null);
    return xmlHttp.responseText;
  }
}

<div *ngIf="(urls$ | async) as urls">
  <div *ngIf="urls.length>1">
    <div ngbDropdown class="d-inline-block">
      <span
        class="d-flex align-items-center text-gray-400 text-hover-primary me-5 ms-0 mb-2 cursor-pointer"
        id="dropdownBasic1"
        ngbDropdownToggle>
        <i *ngIf="icon_i" class="bi me-1 ms-0" [ngClass]="icon_i"></i>
        {{urls[0].group}}
        </span>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <a ngbDropdownItem *ngFor="let u of urls" [href]="u.url" target="_blank">{{u.url}}</a>
      </div>
    </div>


    <!--    <div *ngFor="let u of urls">{{u.url}}</div>-->
  </div>
  <div *ngIf="urls.length==1">
    <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 ms-0 mb-2 cursor-pointer"
       [href]="urls[0].url" target="_blank">
      <i *ngIf="icon_i" class="bi me-1 ms-0" [ngClass]="icon_i"></i>
      {{urls[0].group}}
    </a>
  </div>
</div>

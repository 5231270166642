<ng-container *ngIf="(coinInfo$ | async) as coinInfo">


  <div class="card my-5 p-10">
    <app-tv-chart
      [symbol]="coinInfo.symbol+'USDT'"
    ></app-tv-chart>
  </div>


  <div *ngIf="coinInfo.tags">

    <div *ngIf="coinInfo.description" class="card d-block p-10 mb-10">
      {{coinInfo.description}}
    </div>

    <div class="d-flex flex-wrap flex-stack mb-5">
      <h3 class="fw-bolder my-2">
        {{'COIN.OVERVIEW.TAGS'|translate}}
        <!--        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>-->
      </h3>
    </div>
    <div class="card d-block p-10">
      <span *ngFor="let tag of coinInfo.tags" class="badge bg-info m-1">{{tag}}</span>
    </div>
  </div>

  <div *ngIf="(urls$ | async) as urls">
    <div class="d-flex flex-wrap flex-stack mb-5  mt-10">
      <h3 class="fw-bolder my-2">
        {{'COIN.OVERVIEW.URLS'|translate}}
        <!--        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>-->
      </h3>
    </div>
    <div class="card d-block p-10">
      <a *ngFor="let url of urls" [href]="url.url" class="badge bg-primary m-1" target="_blank">{{url.group}}</a>
    </div>
  </div>

  <div *ngIf="is_empty" class="card p-10">{{'GENERAL.NO_DATA' | translate}}</div>


</ng-container>


import {Component, Input, OnInit} from '@angular/core';
import {interval, Observable} from "rxjs";
import {BinanceService} from "../../services/binance/binance.service";
import {map, mapTo, pluck} from "rxjs/operators";

@Component({
  selector: 'app-price-live',
  templateUrl: './price-live.component.html',
  styleUrls: ['./price-live.component.scss']
})
export class PriceLiveComponent implements OnInit {
  @Input() symbol = ''
  price$: Observable<any>
  loading = true

  constructor(
    private binanceService: BinanceService,
  ) {
  }

  ngOnInit(): void {

    this.getPrice()

  }

  getPrice() {
    this.loading = true
    this.price$ =  this.binanceService.getPrice(this.symbol + "USDT").pipe(
      pluck('price'),
      map(value => {
        this.loading = false
        if (value) {
          return value
        } else {
          return undefined
        }
      })
    )
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {CoinURL_} from "../../api/models/coin-url_";

@Component({
  selector: 'app-urls',
  templateUrl: './urls.component.html',
  styleUrls: ['./urls.component.scss']
})
export class UrlsComponent implements OnInit {
  @Input() urls$: Observable<CoinURL_[]> ;
  @Input() icon_i: string  ;

  constructor() { }

  ngOnInit(): void {
  }

}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RankShot_ } from '../models/rank-shot-_';
@Injectable({
  providedIn: 'root',
})
class RankShotsService extends __BaseService {
  static readonly rankShotsListPath = '/rank-shots/';
  static readonly rankShotsCreatePath = '/rank-shots/';
  static readonly rankShotsReadPath = '/rank-shots/{id}/';
  static readonly rankShotsUpdatePath = '/rank-shots/{id}/';
  static readonly rankShotsPartialUpdatePath = '/rank-shots/{id}/';
  static readonly rankShotsDeletePath = '/rank-shots/{id}/';
  static readonly rankShotsCompareTwoRankShotPath = '/rank-shots/{id}/compare-two-rank-shot/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Rank Shots
   * @param params The `RankShotsService.RankShotsListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  rankShotsListResponse(params: RankShotsService.RankShotsListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<RankShot_>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rank-shots/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<RankShot_>}>;
      })
    );
  }
  /**
   * Rank Shots
   * @param params The `RankShotsService.RankShotsListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  rankShotsList(params: RankShotsService.RankShotsListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<RankShot_>}> {
    return this.rankShotsListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<RankShot_>})
    );
  }

  /**
   * Rank Shots
   * @param data undefined
   */
  rankShotsCreateResponse(data: RankShot_): __Observable<__StrictHttpResponse<RankShot_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rank-shots/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShot_>;
      })
    );
  }
  /**
   * Rank Shots
   * @param data undefined
   */
  rankShotsCreate(data: RankShot_): __Observable<RankShot_> {
    return this.rankShotsCreateResponse(data).pipe(
      __map(_r => _r.body as RankShot_)
    );
  }

  /**
   * Rank Shots
   * @param id A unique integer value identifying this rank shot.
   */
  rankShotsReadResponse(id: number): __Observable<__StrictHttpResponse<RankShot_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rank-shots/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShot_>;
      })
    );
  }
  /**
   * Rank Shots
   * @param id A unique integer value identifying this rank shot.
   */
  rankShotsRead(id: number): __Observable<RankShot_> {
    return this.rankShotsReadResponse(id).pipe(
      __map(_r => _r.body as RankShot_)
    );
  }

  /**
   * Rank Shots
   * @param params The `RankShotsService.RankShotsUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot.
   *
   * - `data`:
   */
  rankShotsUpdateResponse(params: RankShotsService.RankShotsUpdateParams): __Observable<__StrictHttpResponse<RankShot_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rank-shots/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShot_>;
      })
    );
  }
  /**
   * Rank Shots
   * @param params The `RankShotsService.RankShotsUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot.
   *
   * - `data`:
   */
  rankShotsUpdate(params: RankShotsService.RankShotsUpdateParams): __Observable<RankShot_> {
    return this.rankShotsUpdateResponse(params).pipe(
      __map(_r => _r.body as RankShot_)
    );
  }

  /**
   * Rank Shots
   * @param params The `RankShotsService.RankShotsPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot.
   *
   * - `data`:
   */
  rankShotsPartialUpdateResponse(params: RankShotsService.RankShotsPartialUpdateParams): __Observable<__StrictHttpResponse<RankShot_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/rank-shots/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShot_>;
      })
    );
  }
  /**
   * Rank Shots
   * @param params The `RankShotsService.RankShotsPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this rank shot.
   *
   * - `data`:
   */
  rankShotsPartialUpdate(params: RankShotsService.RankShotsPartialUpdateParams): __Observable<RankShot_> {
    return this.rankShotsPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as RankShot_)
    );
  }

  /**
   * Rank Shots
   * @param id A unique integer value identifying this rank shot.
   */
  rankShotsDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rank-shots/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Rank Shots
   * @param id A unique integer value identifying this rank shot.
   */
  rankShotsDelete(id: number): __Observable<null> {
    return this.rankShotsDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Rank Shots
   * @param id A unique integer value identifying this rank shot.
   */
  rankShotsCompareTwoRankShotResponse(id: number): __Observable<__StrictHttpResponse<RankShot_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rank-shots/${encodeURIComponent(String(id))}/compare-two-rank-shot/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RankShot_>;
      })
    );
  }
  /**
   * Rank Shots
   * @param id A unique integer value identifying this rank shot.
   */
  rankShotsCompareTwoRankShot(id: number): __Observable<RankShot_> {
    return this.rankShotsCompareTwoRankShotResponse(id).pipe(
      __map(_r => _r.body as RankShot_)
    );
  }
}

module RankShotsService {

  /**
   * Parameters for rankShotsList
   */
  export interface RankShotsListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
  }

  /**
   * Parameters for rankShotsUpdate
   */
  export interface RankShotsUpdateParams {

    /**
     * A unique integer value identifying this rank shot.
     */
    id: number;
    data: RankShot_;
  }

  /**
   * Parameters for rankShotsPartialUpdate
   */
  export interface RankShotsPartialUpdateParams {

    /**
     * A unique integer value identifying this rank shot.
     */
    id: number;
    data: RankShot_;
  }
}

export { RankShotsService }

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-wallet[address][name][symbol][network]',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
   @Input() address : string
   @Input() name : string
   @Input() symbol : string
   @Input() network : string
   @Input() formatQR = 'jpg'

  // todo: add copy button https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {CoinInfo_} from "../../../../api/models/coin-info-_";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../../modules/auth";

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  coinInfo$: Observable<CoinInfo_>
  coinData$: Observable<any>
  chartOptions: any = {};
  subscribe_status:any

  constructor(
    private translateService: TranslateService,
    private authService:AuthService
  ) {
  }

  ngOnInit(): void {

    this.subscribe_status = this.authService.currentUserValue?.plan_code

    // console.log(this.subscribe_status)

    this.chartOptions = {
    //   series : [
    //   {
    //     name: "XYZ MOTORS",
    //     data: dates
    //   }
    // ],
    chart : {
      type: "area",
      stacked: false,
      height: 450,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: "zoom"
      }
    },
    dataLabels : {
      enabled: false
    },
    markers : {
      size: 0
    },
    title : {
      text: "Ranking Chart - hashtcoin.com",
      align: "left"
    },
    fill : {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis : {
      title: {
        text: this.translateService.get('COIN.RANKING')
      }
    },
    }
  }

}

<!--begin::Row-->
<div *ngIf="(dashboard$ | async) as dashboard" class="row g-5 g-xl-8">
  <div class="col-sm-6 col-xxl-3">
    <!--begin::Statistics Widget 5-->
    <a class="card bg-body  card-xl-stretch mb-xl-8">
      <!--begin::Body-->
      <div class="card-body">
        <i class="bi bi-coin fs-4x text-success"></i>
        <div class="text-gray-900 fw-bolder fs-2 mb-2 mt-5">{{dashboard.count_all_coins | number}}</div>
        <div class="fw-bold text-gray-400">{{'DASHBOARD.COUNT_ALL_COINS' | translate}}</div>
      </div>
      <!--end::Body-->
    </a>
    <!--end::Statistics Widget 5-->
  </div>
  <div class="col-sm-6 col-xxl-3">
    <!--begin::Statistics Widget 5-->
    <a class="card bg-dark  card-xl-stretch mb-xl-8">
      <!--begin::Body-->
      <div class="card-body">
        <i class="bi bi-clipboard-data text-white fs-4x"></i>
        <div class="text-gray-100 fw-bolder fs-2 mb-2 mt-5">{{dashboard.count_all_rankshots | number}}</div>
        <div class="fw-bold text-gray-100">{{'DASHBOARD.COUNT_ALL_RANKSHOTS' | translate}}</div>
      </div>
      <!--end::Body-->
    </a>
    <!--end::Statistics Widget 5-->
  </div>
  <div class="col-sm-6 col-xxl-3">
    <!--begin::Statistics Widget 5-->
    <a class="card bg-warning  card-xl-stretch mb-xl-8">
      <!--begin::Body-->
      <div class="card-body">
        <i class="bi bi-save text-white fs-4x"></i>
        <div class="text-white fw-bolder fs-2 mb-2 mt-5">{{dashboard.count_all_rankshotsD | number}}</div>
        <div class="fw-bold text-white">{{'DASHBOARD.COUNT_ALL_RANKSHOTSD' | translate}}</div>
      </div>
      <!--end::Body-->
    </a>
    <!--end::Statistics Widget 5-->
  </div>
  <div class="col-sm-6 col-xxl-3">
    <!--begin::Statistics Widget 5-->
    <a class="card bg-info  card-xl-stretch mb-5 mb-xl-8">
      <!--begin::Body-->
      <div class="card-body">
        <i class="bi bi-person-bounding-box text-white fs-4x"></i>
        <div class="text-white fw-bolder fs-2 mb-2 mt-5">{{dashboard.count_all_users | number}}</div>
        <div class="fw-bold text-white">{{'DASHBOARD.COUNT_ALL_USERS' | translate}}</div>
      </div>
      <!--end::Body-->
    </a>
    <!--end::Statistics Widget 5-->
  </div>
</div>
<!--end::Row-->


<div class="d-flex flex-wrap flex-stack mb-5">
  <h3 class="fw-bolder my-2">
    {{'DASHBOARD.LAST_COINS_RANK'|translate}}
    <!--        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>-->
  </h3>
</div>
<app-last-rank-analysis></app-last-rank-analysis>

<!--<div class="d-flex flex-wrap flex-stack mb-5">-->
<!--  <h3 class="fw-bolder my-2">-->
<!--    آخرین رتبه های اسکن شده-->
<!--    &lt;!&ndash;        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>&ndash;&gt;-->
<!--  </h3>-->
<!--</div>-->
<app-coins></app-coins>

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CoinInfo_ } from '../models/coin-info-_';
@Injectable({
  providedIn: 'root',
})
class CoinInfosService extends __BaseService {
  static readonly coinInfosListPath = '/coin-infos/';
  static readonly coinInfosCreatePath = '/coin-infos/';
  static readonly coinInfosReadPath = '/coin-infos/{id}/';
  static readonly coinInfosUpdatePath = '/coin-infos/{id}/';
  static readonly coinInfosPartialUpdatePath = '/coin-infos/{id}/';
  static readonly coinInfosDeletePath = '/coin-infos/{id}/';
  static readonly coinInfosGetLastMarcetcapReadPath = '/coin-infos/{id}/get_last_marcetcap/';
  static readonly coinInfosGetLastRankPath = '/coin-infos/{id}/get_last_rank/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * More Information about Coins
   * @param params The `CoinInfosService.CoinInfosListParams` containing the following parameters:
   *
   * - `tags`:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `category`:
   */
  coinInfosListResponse(params: CoinInfosService.CoinInfosListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<CoinInfo_>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tags != null) __params = __params.set('tags', params.tags.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.category != null) __params = __params.set('category', params.category.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coin-infos/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<CoinInfo_>}>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param params The `CoinInfosService.CoinInfosListParams` containing the following parameters:
   *
   * - `tags`:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   *
   * - `category`:
   */
  coinInfosList(params: CoinInfosService.CoinInfosListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<CoinInfo_>}> {
    return this.coinInfosListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<CoinInfo_>})
    );
  }

  /**
   * More Information about Coins
   * @param data undefined
   */
  coinInfosCreateResponse(data: CoinInfo_): __Observable<__StrictHttpResponse<CoinInfo_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/coin-infos/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinInfo_>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param data undefined
   */
  coinInfosCreate(data: CoinInfo_): __Observable<CoinInfo_> {
    return this.coinInfosCreateResponse(data).pipe(
      __map(_r => _r.body as CoinInfo_)
    );
  }

  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosReadResponse(id: string): __Observable<__StrictHttpResponse<CoinInfo_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coin-infos/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinInfo_>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosRead(id: string): __Observable<CoinInfo_> {
    return this.coinInfosReadResponse(id).pipe(
      __map(_r => _r.body as CoinInfo_)
    );
  }

  /**
   * More Information about Coins
   * @param params The `CoinInfosService.CoinInfosUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this coin info.
   *
   * - `data`:
   */
  coinInfosUpdateResponse(params: CoinInfosService.CoinInfosUpdateParams): __Observable<__StrictHttpResponse<CoinInfo_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/coin-infos/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinInfo_>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param params The `CoinInfosService.CoinInfosUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this coin info.
   *
   * - `data`:
   */
  coinInfosUpdate(params: CoinInfosService.CoinInfosUpdateParams): __Observable<CoinInfo_> {
    return this.coinInfosUpdateResponse(params).pipe(
      __map(_r => _r.body as CoinInfo_)
    );
  }

  /**
   * More Information about Coins
   * @param params The `CoinInfosService.CoinInfosPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this coin info.
   *
   * - `data`:
   */
  coinInfosPartialUpdateResponse(params: CoinInfosService.CoinInfosPartialUpdateParams): __Observable<__StrictHttpResponse<CoinInfo_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/coin-infos/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinInfo_>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param params The `CoinInfosService.CoinInfosPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A UUID string identifying this coin info.
   *
   * - `data`:
   */
  coinInfosPartialUpdate(params: CoinInfosService.CoinInfosPartialUpdateParams): __Observable<CoinInfo_> {
    return this.coinInfosPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as CoinInfo_)
    );
  }

  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/coin-infos/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosDelete(id: string): __Observable<null> {
    return this.coinInfosDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosGetLastMarcetcapReadResponse(id: string): __Observable<__StrictHttpResponse<CoinInfo_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coin-infos/${encodeURIComponent(String(id))}/get_last_marcetcap/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinInfo_>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosGetLastMarcetcapRead(id: string): __Observable<CoinInfo_> {
    return this.coinInfosGetLastMarcetcapReadResponse(id).pipe(
      __map(_r => _r.body as CoinInfo_)
    );
  }

  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosGetLastRankResponse(id: string): __Observable<__StrictHttpResponse<CoinInfo_>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coin-infos/${encodeURIComponent(String(id))}/get_last_rank/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoinInfo_>;
      })
    );
  }
  /**
   * More Information about Coins
   * @param id A UUID string identifying this coin info.
   */
  coinInfosGetLastRank(id: string): __Observable<CoinInfo_> {
    return this.coinInfosGetLastRankResponse(id).pipe(
      __map(_r => _r.body as CoinInfo_)
    );
  }
}

module CoinInfosService {

  /**
   * Parameters for coinInfosList
   */
  export interface CoinInfosListParams {
    tags?: string;

    /**
     * A search term.
     */
    search?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    category?: string;
  }

  /**
   * Parameters for coinInfosUpdate
   */
  export interface CoinInfosUpdateParams {

    /**
     * A UUID string identifying this coin info.
     */
    id: string;
    data: CoinInfo_;
  }

  /**
   * Parameters for coinInfosPartialUpdate
   */
  export interface CoinInfosPartialUpdateParams {

    /**
     * A UUID string identifying this coin info.
     */
    id: string;
    data: CoinInfo_;
  }
}

export { CoinInfosService }

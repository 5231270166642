import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CoinInfosService} from "../../../api/services/coin-infos.service";
import {concatMap, groupBy, map, pluck, switchMap, tap} from "rxjs/operators";
import {from, Observable, of} from "rxjs";
import {CoinInfo_} from "../../../api/models/coin-info-_";
import {CoinUrlsService} from "../../../api/services/coin-urls.service";
import {CoinURL_} from "../../../api/models/coin-url_";
import * as _ from 'lodash'
import {take} from "lodash";
import {RankShotD_} from "../../../api/models/rank-shot-d_";
import {RankShotDsService} from "../../../api/services/rank-shot-ds.service";
import {CoinService} from "../../../api/services/coin.service";
import {RankingComponent} from "./ranking/ranking.component";
import {OverviewComponent} from "./overview/overview.component";
import {GeneralService} from "../../../services/general/general.service";

@Component({
  selector: 'app-coin',
  templateUrl: './coin.component.html',
  styleUrls: ['./coin.component.scss']
})
export class CoinComponent implements OnInit {
  id_coin: string | ''
  coinInfo$: Observable<CoinInfo_>
  coinData$: Observable<any>
  ranks$: Observable<RankShotD_[]>
  urls$: Observable<CoinURL_[]>
  website_urls$: Observable<CoinURL_[]>
  explorer_urls$: Observable<CoinURL_[]>

  urls_grouped$: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private coinInfosService: CoinInfosService,
    private coinUrlsService: CoinUrlsService,
    private coinService: CoinService,
    private generalService: GeneralService,
  ) {

  }

  ngOnInit(): void {
    this.id_coin = this.activatedRoute.snapshot.paramMap.get('id_coin') || '';
    this.coinInfo$ = this.coinInfosService.coinInfosRead(this.id_coin).pipe(
      map(value => {
        // this.ranks$ = this.rankShotDsService.rankShotDsList({coin: value.id})
        this.coinData$ = this.coinService.coinRead(value.id || '')

        this.urls$ = this.coinUrlsService.coinUrlsList({coin: value.id}).pipe(
          pluck('results'),
        )
        this.website_urls$ = this.urls$.pipe(
          map(value1 => {
            return value1.filter(u => u.group_en == 'website')
          })
        )
        this.explorer_urls$ = this.urls$.pipe(
          map(value1 => {
            return value1.filter(u => u.group_en == 'explorer')
          })
        )
        return value
      })
    )
    this.coinInfo$.subscribe(value => {
      this.generalService.Title({translate: false, titleString: value.name || ''})
    })

  }

  onActivate($event: any) {
    if ($event instanceof RankingComponent) {
      // $event.coinInfo$ = this.coinInfo$;
      $event.coinData$ = this.coinData$;

      // $event.changed.subscribe((event) => {
      //   this.handleChildChange(event);
      // });
    } else if ($event instanceof OverviewComponent) {
      // $event.coinInfo$ = this.coinInfo$;
      // $event.coinInfo$ = this.coinInfo$;
      $event.id_coin = this.id_coin;
      $event.urls$ = this.urls$;
    }
  }
}

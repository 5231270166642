<!--begin::Root-->
<div class="d-flex flex-column flex-root h-100">
  <!--begin::Authentication - 404 Page-->
  <div class="d-flex justify-content-center flex-column flex-center flex-column-fluid align-items-center p-10 my-auto">
    <i class="bi bi-x-circle text-danger fs-5x"></i>
    <h1 class="fw-bold mb-10 text-danger fs-3x">
      {{'SUBSCRIBE.ERROR_PAYMENT.TITLE'|translate}}
    </h1>
    <p class="fw-bold mb-10 fs-2" style="color: #A3A3C7">
      {{'SUBSCRIBE.ERROR_PAYMENT.DESCRIPTION'|translate}}
    </p>
    <div>
      <a [routerLink]="['/dashboard']" class="btn btn-primary mx-2">
        {{'GENERAL.RETURN_HOME'|translate}}
      </a>
      <a href="mailto:info@hashtcoin.com" class="btn btn-primary mx-2">
        {{'CONTACT.MENU_TITLE'|translate}}
      </a>
    </div>
  </div>
  <!--end::Authentication - 404 Page-->
</div>
<!--end::Root-->

import {Component, OnInit, TemplateRef} from '@angular/core';
import {ToastService} from "../toast.service";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  host: {'class': 'toast-container position-fixed bottom-0 end-0 p-3 fs-2x', 'style': 'z-index: 1200;'}
})
export class ToastComponent implements OnInit {

  constructor(
    public toastService: ToastService
  ) { }

  isTemplate(toast:any) { return toast.textOrTpl instanceof TemplateRef; }

  ngOnInit(): void {
  }

}

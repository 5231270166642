import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {fromFetch} from "rxjs/fetch";
import {catchError, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BinanceService {

  constructor() {
  }

  getPrice(symbol: string): Observable<any> {
    if (symbol == 'USDTUSDT') {
      return of({
        "symbol": "USDTUSDT",
        "price": "1"
      })
    }
    const url = 'https://api.binance.com/api/v3/ticker/price?symbol=' + symbol
    const data$ = fromFetch(url).pipe(
      switchMap(response => {
        if (response.ok) {
          // OK return data
          return response.json();
        } else {
          // Server is returning a status requiring the client to try something else.
          return of({error: true, message: `Error ${response.status}`});
        }
      }),
      catchError(err => {
        // Network or other error, handle appropriately
        console.error(err);
        return of({error: true, message: err.message})
      })
    );

    return data$;
  }
}

<ngb-alert *ngIf="!subscribe_status" type="warning" [dismissible]="false" >
  {{'COIN.RANKING.ALERT_MORE_DATA' | translate}}
  <a [routerLink]="['/subscribe']" >{{'SUBSCRIBE.COMPARE' | translate}}</a>


</ngb-alert>
<div *ngIf="(coinData$ |async) as coinData " class="card p-10">

  <apx-chart
    [style.direction]="'ltr'"
    [series]="[
          {
            name: ('COIN.RANK' | translate),
            data: coinData.ranks.ranks
          }
        ]"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [markers]="chartOptions.markers"
    [title]="chartOptions.title"
    [fill]="chartOptions.fill"
    [xaxis]="{ categories: coinData.ranks.rankShot__created_time,type:'datetime'  }"
    [autoUpdateSeries]="true"
  ></apx-chart>
</div>

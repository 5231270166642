import {Component, OnInit} from '@angular/core';
import {RankAnalysisService} from "../../../api/services/rank-analysis.service";
import {empty, Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {getCSSVariableValue} from "../../../_metronic/kt/_utils";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import moment from 'moment';
import {start} from "@popperjs/core";
import {GeneralService} from "../../../services/general/general.service";

@Component({
  selector: 'app-last-rank-analysis',
  templateUrl: './last-rank-analysis.component.html',
  styleUrls: ['./last-rank-analysis.component.scss']
})
export class LastRankAnalysisComponent implements OnInit {
  rootUrl: string = environment.mediaUrl;
  chartOptions: any = {};
  loading = true
  duration: string;
  updated: string;
  analysis$: Observable<any>
  type: string

  constructor(
    private rankAnalysisService: RankAnalysisService,
    private router: Router,
    private generalService: GeneralService,
  ) {
    const type = this.router.url.split('/')[this.router.url.split('/').length - 1]
    if (type == 'last') {
      this.generalService.Title({translate: true, titleString: 'RANK_ANALYSIS.TITLE'},
        {translate: true, titleString: 'RANK_ANALYSIS.LAST'})
    } else if (type == '1-dayago'){
      this.generalService.Title({translate: true, titleString: 'RANK_ANALYSIS.TITLE'},
        {translate: true, titleString: 'RANK_ANALYSIS.ONE_DAYAGO'})
    } else if (type == '3-dayago'){
      this.generalService.Title({translate: true, titleString: 'RANK_ANALYSIS.TITLE'},
        {translate: true, titleString: 'RANK_ANALYSIS.THREE_DAYAGO'})
    }  else if (type == '7-dayago'){
      this.generalService.Title({translate: true, titleString: 'RANK_ANALYSIS.TITLE'},
        {translate: true, titleString: 'RANK_ANALYSIS.SEVEN_DAYAGO'})
    }  else if (type == '30-dayago'){
      this.generalService.Title({translate: true, titleString: 'RANK_ANALYSIS.TITLE'},
        {translate: true, titleString: 'RANK_ANALYSIS.THIRTY_DAYAGO'})
    }
  }

  ngOnInit(): void {
    const thisURL = this.router.url;
    const thisURLArray = thisURL.split('/')
    this.type = thisURLArray[thisURLArray.length - 1]
    const labelColor = getCSSVariableValue('--bs-gray-500');
    const borderColor = getCSSVariableValue('--bs-gray-200');
    const baseColor = getCSSVariableValue('--bs-primary');
    const secondaryColor = getCSSVariableValue('--bs-gray-300');
    this.chartOptions = {
      labels: ["New Rank Coins", "Old Rank Coins"],
      chart: {
        type: "donut",
        height: 125,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'left',
      },
      plotOptions: {
        pie: {}
      },


    };

    this.loading = true
    this.analysis$ = this.rankAnalysisService
      .rankAnalysisRead({newRankshotId: '2', oldRankshotId: '1', type: this.type})
      .pipe(
        map(value => {
          this.loading = false
          const data: any = value
          const newTime = new Date(data.info.new_rank_shot_time)
          const oldTime = new Date(data.info.old_rank_shot_time)
          const a = newTime.getTime() - oldTime.getTime()
          moment.locale(localStorage.getItem('language') || 'en')
          this.duration = moment.duration(a).humanize()
          this.updated = moment(newTime).fromNow()

          return value
        }),
        catchError((err, caught) => {
          console.log(err)
          // return caught
          return ''
        })
      )

  }

}


import * as data from './fa.json'
export const locale = {
  lang: 'fa',
  data:data,
  // data: {
  //   GENERAL: {
  //     WAIT: 'لطفا صبر کنید',
  //     OR: 'یا',
  //     SUBMIT: 'تایید',
  //     OK: 'باشه',
  //     CANCEL: 'لغو',
  //     DOLLAR: 'دلار',
  //     WEBNESHIN: 'وب نشین',
  //     HASHTCOIN: 'هشت کوین',
  //     AGO: 'قبل',
  //     FOR: 'برای',
  //     NO_DATA: 'اطلاعاتی موجود نیست!',
  //     DAY: 'روز',
  //     WEEK: 'هفته',
  //     MONTH: 'ماه',
  //     YEAR: 'سال',
  //     UNLIMITED: 'بدون محدودیت',
  //     RETURN_HOME: 'بازگشت به خانه',
  //   },
  //   ENGAGE: {
  //     FEEDBACK: 'بازخورد',
  //   },
  //   TOPBAR: {
  //     LOGIN: 'ورود',
  //     SIGNUP: 'ثبت نام',
  //     USER: {
  //       PROFILE: 'پروفایل',
  //       LOGOUT: 'خروج',
  //     },
  //   },
  //   AUTH: {
  //     GENERAL: {
  //       EMAIL: 'ایمیل',
  //       FIRSTNAME: 'نام',
  //       LASTNAME: 'نام خانوادگی',
  //       PASSWORD: 'رمز عبور',
  //       CPASSWORD: 'تکرار رمز عبور',
  //       ERROR_CPASSWORD_NOT_MATCH: 'رمز عبور با تکرارش برابر نیست!',
  //       ORDINARY: 'عادی',
  //     },
  //     LOGIN: {
  //       TITLE: 'ورود به حساب کاربری',
  //       NEW_USER: 'حساب کاربری ندارید؟',
  //       CREATE_USER: 'بسازید!',
  //       ERROR: 'حساب کاربری با این اطلاعات وجود ندارد.',
  //       FORGOT: 'فراموش کرده اید؟',
  //       LOGIN: 'ورود',
  //       CONTINUE_WITH_GOOGLE: 'ادامه با حساب گوگل',
  //     },
  //     RESET_PASSWORD: {
  //       TITLE: 'بازیابی رمز عبور',
  //       DESCRIPTION: 'جهت بازیابی رمز عبور لطفا ایمیل خود را وارد نمایید.',
  //       SUCCESS: 'ایمیل بازیابی رمز عبور ارسال شد. لطفا ایمیل خود را چک کنید.',
  //       ERROR: 'متاسفیم! به نظر می آید مشکلی پیش آمده. لطفا مجدد امتحان کنید.',
  //     },
  //     RESET_PASSWORD_CONFIRM: {
  //       TITLE: 'رمز عبور جدید',
  //       DESCRIPTION: 'لطفا رمز جدید خود را وارد نمایید.',
  //       SUCCESS: 'ایمیل بازیابی رمز عبور ارسال شد. لطفا ایمیل خود را چک کنید.',
  //       ERROR: 'متاسفیم! به نظر می آید مشکلی پیش آمده. لطفا مجدد امتحان کنید.',
  //     },
  //     SIGNUP: {
  //       TITLE: 'ساخت اکانت جدید',
  //       DESCRIPTION: 'اکانت دارید؟',
  //       TERMS: 'کلیه قوانین این سایت را قبول دارم.',
  //     },
  //   },
  //   BLOG: {
  //     ABOUT: {
  //       TITLE: 'درباره ما'
  //     },
  //     CONTACT: {
  //       TITLE: 'تماس با ما'
  //     }
  //
  //   },
  //   DASHBOARD: {
  //     TITLE: 'در یک نگاه',
  //     MENU_TITLE: 'در یک نگاه',
  //     COUNT_ALL_COINS: 'تعداد رمز ارزهای ثبت شده',
  //     COUNT_ALL_RANKSHOTS: 'تعداد دفعات آنالیز coinmarketcap',
  //     COUNT_ALL_RANKSHOTSD: 'تعداد رتبه های ذخیره شده',
  //     COUNT_ALL_USERS: 'تعداد کاربران ثبت شده',
  //     LAST_COINS_RANK: 'آخرین تغییرات رتبه های اسکن شده',
  //   },
  //   COINS: {
  //     TITLE: 'رمز ارزها',
  //     MENU_TITLE: 'رمز ارزها',
  //     LAST_UPDATE_RANKING: 'آخرین بروزرسانی رنکینگ',
  //     CATEGORY: 'نوع',
  //     PRICE: 'قیمت',
  //     MARKETCAP: 'ارزش بازار',
  //   },
  //   COIN: {
  //     ACTIVE: 'فعال',
  //     DEACTIVE: 'غیرفعال',
  //     CURRENT_RANK: 'رتبه فعلی',
  //     MIN_RANK: 'بهترین رتبه',
  //     MAX_RANK: 'بدترین رتبه',
  //     RANK: 'رتبه',
  //     RANKING: {
  //       TITLE: 'رتبه بندی',
  //       ALERT_MORE_DATA: 'اطلاعات این نمودار محدود است. جهت دسترسی به اطلاعات قدیمی تر اشتراک حرفه ای و یا حرفه ای پلاس تهیه نمایید.',
  //     },
  //     OVERVIEW: {
  //       TITLE: 'نگاه کلی',
  //       TAGS: 'تگ ها',
  //       URLS: 'لینک های مرتبط',
  //     },
  //   },
  //   RANK_ANALYSIS: {
  //     TITLE: 'آنالیز رتبه',
  //     MENU_TITLE: 'آنالیز رتبه',
  //     WAIT: 'درحال مقایسه بیش از 18000 رمز ارز. ممکن است چند ثاتیه طول بکشد. لطفا شکیبا باشید!',
  //     LAST: 'آخرین تغییرات',
  //     ONE_DAYAGO: 'مقایسه با دیروز',
  //     THREE_DAYAGO: 'مقایسه با 3 روز پیش',
  //     SEVEN_DAYAGO: 'مقایسه با هفته پیش',
  //     THIRTY_DAYAGO: 'مقایسه با ماه پیش',
  //     DELTA_NEW_OLD_TIME: 'فاصله زمانی بین دو موقعیت',
  //     UPDATE: 'بروزرسانی:',
  //     NEW_RANK_SHOT_TIME: 'زمان موقعیت جدیدتر',
  //     OLD_RANK_SHOT_TIME: 'زمان موقعیت قدیمی تر',
  //     NEW_COINS_IN_LAST_RANK_SHOT: 'رمز ارز جدید در موقعیت جدیدتر',
  //     NEW_IN_TOP_100: 'تازه واردهای صدتای برتر',
  //     EMPTY_NEW_IN_TOP_100: 'در این بازه زمانی ارزی به 100 رتبه برتر اضافه نشده است.',
  //     NEW_RANK: 'رتبه جدید',
  //     OLD_RANK: 'رتبه قبلی',
  //     CHANGEPLUS: 'پله صعود رتبه',
  //     CHANGEPPLUS: 'درصد صعود رتبه',
  //     COUNT_ALL_RANKS_COINS: 'تعداد رمز ارزهای آنالیز شده',
  //     GROWTHS_IN_TOP_500: 'بیشترین رشد در 500 ارز اول',
  //     GROWTHS_IN_ALL: 'بیشترین رشد در کل',
  //     TOP_20_COIN_SORT_BY_CHANGE_RANK: '20 ارز برتر بر اساس تعداد پله صعود',
  //     TOP_20_COIN_SORT_BY_CHANGE_PERCENT_RANK: '20 ارز برتر بر اساس درصد پله صعود',
  //     IN_FIRST_500: 'در بین 500 ارز اول رمز ارزهای',
  //     IN_ALL: 'در بین تمامی رمز ارزها',
  //   },
  //   CONTACT: {
  //     TITLE: 'تماس با ما',
  //     MENU_TITLE: 'تماس با ما',
  //   },
  //   US: {
  //     TITLE: 'درباره ما',
  //     MENU_TITLE: 'درباره ما',
  //     PRICE_BTN: 'قیمت ها',
  //     DOCS_BTN: 'راهنما',
  //     DESCRIPTION01: 'تحلیل و بررسی دقیق اطلاعات یکی از مهم ترین عوامل موفقیت در بازار های مالی می باشد.',
  //     DESCRIPTION02: 'ما به شما کمک می کنیم هوشمندانه تر در بازار پر فراز و نشیب رمز ارزها قدم بردارید.',
  //     P01: `در حال حاضر بیش از 9000 رمز ارز در سایت کوین مارکت کپ ثبت شده است. انتخاب بهترین گزینه جهت سرمایه گذاری های بلند مدت و یا کوتاه مدت کار بسیار پر ریسکی می باشد. یکی از خدمات ما برای شما این است که با تجزیه و تحلیل رتبه های رمز ارزها به شما کمک می کنیم تا مواردی که ممکن است مستعد رشد باشند را جهت بررسی های بیشتر برای سرمایه گذاری شناسایی کنید.`,
  //     P02: `این روش از مقایسه رمز ارزها با یکدیگر باعث می شود درک درستی از روند ارزش آلت کوین ها داشته باشیم. برای مثال ممکن است یک توکن قیمت کاهشی داشته باشد اما نسبت به آلت کوین های هم ارزش خود کاهش کم تری را تجربه کند. خود این روند نشانگر این است که توکن مربوطه می تواند مورد مناسبی جهت بررسی های بیشتر برای سرمایه گذاری باشد.`,
  //     P03: `یکی دیگر از خدمات ما برنامه نویسی در حوزه معاملاتیست. متخصصان ما استراتژی های معاملاتی را به زبان کامپیوتر ترجمه می کنند. سپس با اطلاعات قیمت گذشته استراتژی را مورد آزمون قرار می دهند و مشخص می کنند که طی دوره مشخص چه معاملاتی انجام می داده و در نهایت به چه میزان سود و یا زیان می کرده است. به این روش اصطلاحا بک تست گفته می شود. بک تست می تواند با رمز ارزهای مخنلف و در تایم فرم های متفاوت انجام شود. اطلاعات بک تست می تواند برای استفاده از یک استراتژی بسیار مهم باشد.`,
  //     P04: `در مر حله بعد به یکی از مهمترین مراحل معاملاتی می رسیم! مشخص کردن پارامتر های بهینه یک استراتژی برای جفت ارز معاملاتی و تایم فریم مشخص. ما بک تست استراتژی معاملاتی را با پارامتر های مختلف ده ها هزار بار محاسبه می کنیم تا بهترین پارامتر ها برای سیستم معاملاتی مشخص شوند.`,
  //     P05: `شاید پر استفاده ترین ابزار معامله گران سایت پر طرفدار tradingview باشد. ما می توانیم ایده های و روش های اطلاعاتی و استراتژی شما را به زبان برنامه نویسی Pine script بنویسیم تا در tradingview قابل استفاده باشد.`,
  //     COIN: 'رمز ارز',
  //     ANALYSIS: 'تحلیل و بررسی',
  //     CUSTOMER: 'مشتری',
  //     P06: `تمام تلاش ماست که هروز بهتر از روز گذشته باشیم. امیدواریم این آمار هروز بهتر از قبل باشند و پارامتر های آماری جدید اضافه کنیم.`,
  //     SERVICES: {
  //       TITLE: 'خدمات',
  //       DESCRIPTION01: 'هدف ما ارائه خدمات کاربردی برای مشتریان است',
  //       DESCRIPTION02: 'برای اطلاعات از این خدمات ما به موارد زیر توجه فرمایید.',
  //       S01: {
  //         TITLE: 'تحلیل و بررسی داده ها',
  //         DESCRIPTION: 'یکی از پر کاربرد ترین خدمات ما تحلیل و بررسی داده ها می باشد. یک نمونه فعال و پر فروش آنالیز رتبه های کوین مارکت کپ است.',
  //       },
  //       S02: {
  //         TITLE: 'برنامه نویسی معاملاتی و مالی',
  //         DESCRIPTION: 'که شامل مواردی همچون بک تست، نقطه بهینه یابی استراتژی معاملاتی، اندیکاتور ها و استراتژی ها در تریدینگ ویو و ... می باشد.',
  //       },
  //       S03: {
  //         TITLE: 'ابزار معاملاتی در تریدینگ ویو',
  //         DESCRIPTION: 'دو ابزار ارائه شده در تریدینگ ویو که یکی استراتژی وب نشین است و دیگری اندبکاتور چند-چند می باشد که نتیجه لحظه ای مبارزه بین خریداران و فروشندگان می پردازد.',
  //       }
  //     },
  //   },
  //   WALLET: {
  //     TITLE: 'آدرس های کیف پول',
  //     MENU_TITLE: 'آدرس های کیف پول',
  //     DESCRIPTION1:'لطفا به انتخاب صحیح شبکه رمز ارز دقت داشته باشید.',
  //     DESCRIPTION2:'لطفا دقت داشته باشید که مبلغ ارسال شده بعد از کسر هزینه انتقال، معادل مبلغ محاسبه شده برای شما باشد.',
  //     DESCRIPTION3:'بعد از ارسال مبلغ رمز ارز، شبکه انتخاب شده و کد پیگیری انتقال را برای ما ارسال نمایید.',
  //   },
  //   PROGRAMMING: {
  //     TITLE: 'برنامه نویسی ترید',
  //     MENU_TITLE: 'برنامه نویسی ترید',
  //     BACKTEST_OPTIMIZATION: 'بک تست و بهینه سازی استراتژی',
  //     TRADINGVIEW_STRATEGY: 'برنامه نویسی Tradingview',
  //   },
  //   SUBSCRIBE: {
  //     MENU_TITLE: 'خرید اشتراک حرفه ای',
  //     RENEW_OR_UPGRADE_SUBSCRIPTION: 'تمدید یا ارتقا اشتراک',
  //     COMPARE: 'بررسی اشتراک ها',
  //     CHOOSE_YOUR_PLAN: 'نوع اشتراک را انتخاب کنید',
  //     CHOOSE_YOUR_PLAN_DESCRIPTION: 'اگر سوالی دارید با ما در تماس باشید.',
  //     VALIDITY_REMAINS: 'اعتبار باقی می ماند',
  //     MONTHLY: 'ماهانه',
  //     ANNUAL: 'سالانه',
  //     FREE: 'رایگان',
  //     FOR_ALWAYS: 'برای همیشه',
  //     TRIAL: 'آزمایشی',
  //     TRIAL_DES: 'مناسب برای آزمایش و آشنایی با نحوه آنالیز دیتا!',
  //     PRO: 'حرفه ای',
  //     PRO_DES: 'رمز ارزهایی که در حال رشد هستند را شکار کنید!',
  //     PRO_PLUS: 'حرفه ای پلاس',
  //     PRO_PLUS_DES: 'مقایسه حرفه ای قدرت رمز ارزها با یکدیگر!',
  //     FEATURES: {
  //       '01': 'دسترسی به رتبه ها',
  //       '02': 'نمودار، csv، svg و png رتبه ها',
  //       '03': 'آنالیز آخرین اسکن رتبه ها',
  //       '04': 'آنالیز 1 روزه رتبه ها',
  //       '05': 'آنالیز 3 روزه رتبه ها',
  //       '06': 'آنالیز 1 هفته رتبه ها',
  //       '07': 'آنالیز 1 ماه رتبه ها',
  //       '08': 'آنالیز سفارشی رتبه ها',
  //       '09': 'دسترسی به تاریخچه آنالیزها',
  //     },
  //     COMING_SOON: 'به زودی',
  //     PAYMENT: 'پرداخت',
  //     SUCCESS_PAYMENT: {
  //       TITLE: 'پرداخت شما با موفقیت ثبت شد!',
  //       DESCRIPTION: 'لطفا جهت بررسی نهایی و تایید دریافت واریزی صبر نمایید. در صورت بروز هرگونه مشکل با ما در تماس باشید.',
  //     },
  //     ERROR_PAYMENT: {
  //       TITLE: 'پرداخت شما با مشکل مواجه شد!',
  //       DESCRIPTION: 'لطفا مجدد تلاش کنید و در صورت بروز مشکل با ما تماس بگیرید.',
  //     },
  //   },
  //   HELP:{
  //     TITLE: 'راهنما',
  //     MENU_TITLE: 'راهنما',
  //     DESCRIPTION: 'در این قسمت سعی شده است تا به سوالاتی که بیشتر از ما پرسیده شده است پاسخ دهیم. اگر هنوز به جواب سوالتان نرسیده اید با ما در تماس باشید.',
  //     PART1:{
  //       TITLE:'هشت کوین',
  //       QA01:{
  //         Q:'هشت کوین چیست؟',
  //         A:'ما یک تیم تحلیل داده ها هست. تیم ما تحلیل و بررسی های متعددی روی دیتای حوزه های متفاوت انجام داده است. در این سایت تلاش کرده ایم که برخی از ابزارهایی که می تواند برای عموم معامله گران رمز ارزها مفید باشد ارائه دهیم.',
  //       },
  //       QA02:{
  //         Q:'آنالیز رنکینگ چیست؟',
  //         A:'سایت coinmarketcap تمامی ارزهای دیجیتال را ثبت کرده و با استفاده از پارامترهایی مانند Market Cap آنها را رتبه بندی می کند. آنالیز رنکینگ این رتبه بندی ها را رسد می کند و با تحلیل این داده ها، جا به جایی رتبه ها را بدست می آورد و اطلاعات مفید این موارد را نمایش می دهد.',
  //       },
  //       QA03:{
  //         Q:'چه ابزارهای دیگری ارائه خواهند شد؟',
  //         A:'ما در تلاشیم تا تجربیات خود را در معاملات رمز ارزها به صورت ابزارهای قابل استفاده برای عموم معامله گران ارائه دهیم. این ابزار ها به صورت استفاده شخصی ساخته شده اند و پروسه تبدیل آن به ابزار قابل استفاده برای همه کمی زمان بر است. ما در تلاشیم تا به سرعت این ابزار ها را ارائه دهیم. در نظر داشته باشید با افزوده شدن ابزارهای جدید هزینه اشتراک ها و تخفیفات ارائه شده تغییر خواهد کرد.',
  //       },
  //     },
  //     PART2:{
  //       TITLE:'پرداخت',
  //       QA01:{
  //         Q:'من پرداخت را در nowpayments.io انجام داده ام اما هنوز اکانت ویژه دریافت نکرده ام.',
  //         A:'لطفا شکیبا باشید! بعد از پرداخت شما، nowpayments رمز ارز پرداختی شما را به رمز ارزهای مورد تایید ما تبدیل می کند و سپس به ولت ما ارسال می کند. این روند ممکن اسن کمی زمان بر باشد. ما از شما پوزش می خواهیم.',
  //       },
  //       QA02:{
  //         Q:'آیا می توانم مبلغ را مستقیما به ولت شما ارسال کنم؟',
  //         A:'بله! شما می توانید از منوی کناری به آدرس ولت های استیبل هشت کوین دسترسی داشته باشید. لطفا بعد از پرداخت دقیق مبلغ اعلام شده اطلاعات پرداخت را از طریق منوی تماس با ما برای ما ارسال نمایید. لطفا در نظر داشته باشید هزینه انتقال با شماست!',
  //       },
  //     },
  //   },
  //   ERROR:{
  //     GO_TO_HOME:'برگشت به خانه',
  //     404: {
  //     TITLE: 'صفحه پیدا نشد',
  //     DESCRIPTION01: 'صفحه ای که به دنبال آن هستید پیدا نشد!',
  //     DESCRIPTION02: ''
  //   },
  //   },
  //
  //
  // }
};

<!--begin::About card-->
<div class="card">
  <!--begin::Body-->
  <div class="card-body p-lg-17">
    <!--begin::About-->
    <div class="mb-18">
      <!--begin::Wrapper-->
      <div class="mb-10">
        <!--begin::Top-->
        <div class="text-center mb-15">
          <!--begin::Title-->
          <h3 class="fs-2hx text-dark mb-5">{{'US.TITLE' | translate}}</h3>
          <!--end::Title-->
          <!--begin::Text-->
          <div class="fs-3 text-muted fw-bold">
            {{'US.DESCRIPTION01' | translate }} <br>
            {{'US.DESCRIPTION02' | translate }}
          </div>
          <!--end::Text-->
        </div>
        <!--end::Top-->
        <!--begin::Overlay-->
        <div class="overlay">
          <!--begin::Image-->
          <img class="w-100 card-rounded" src="assets/media/stock/2000x800/1.jpg" alt=""/>
          <!--end::Image-->
          <!--begin::Links-->
          <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
            <a [routerLink]="['/subscribe']" class="btn btn-primary">
              {{'US.PRICE_BTN' | translate}}
            </a>
<!--            <a (click)="onDocsReq()" class="btn btn-light-primary ms-3">-->
<!--              {{'US.DOCS_BTN' | translate}}-->
<!--            </a>-->
          </div>
          <!--end::Links-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Wrapper-->
      <!--begin::Description-->
      <div class="fs-4 fw-bold text-gray-600" style="text-align: justify">
        <!--begin::Text-->
        <p class="mb-8" translate="US.P01"></p>
        <p class="mb-8" translate="US.P02"></p>
        <hr>
        <p class="mb-8" translate="US.P03"></p>
        <p class="mb-8" translate="US.P04"></p>
        <p class="mb-8" translate="US.P05"></p>
        <!--end::Text-->
        <!--begin::Text-->
        <!--        <p class="mb-8">There’s an old maxim that states,-->
        <!--          <span class="text-gray-800 pe-1">“No fun for the writer, no fun for the reader.”</span>No matter what industry-->
        <!--          you’re working in, as a blogger, you should live and die by this statement.</p>-->
        <!--end::Text-->
        <!--begin::Text-->
        <!--        <p class="mb-8">Before you do any of the following steps, be sure to pick a topic that actually interests you.-->
        <!--          Nothing – and-->
        <!--          <a href="/metronic8/demo1/../demo1/pages/blog/home.html" class="link-primary pe-1">I mean NOTHING</a>– will-->
        <!--          kill a blog post more effectively than a lack of enthusiasm from the writer. You can tell when a writer is-->
        <!--          bored by their subject, and it’s so cringe-worthy it’s a little embarrassing.</p>-->
        <!--end::Text-->
        <!--begin::Text-->
        <!--        <p class="mb-17">I can hear your objections already. “But Dan, I have to blog for a cardboard box manufacturing-->
        <!--          company.” I feel your pain, I really do. During the course of my career, I’ve written content for dozens of-->
        <!--          clients in some less-than-thrilling industries (such as financial regulatory compliance and corporate-->
        <!--          housing), but the hallmark of a professional blogger is the ability to write well about any topic, no matter-->
        <!--          how dry it may be. Blogging is a lot easier, however, if you can muster at least a little enthusiasm for the-->
        <!--          topic at hand.</p>-->
        <!--end::Text-->
      </div>
      <!--end::Description-->
    </div>
    <!--end::About-->
    <!--begin::Statistics-->
    <div class="card bg-light mb-18">
      <!--begin::Body-->
      <div class="card-body py-15">
        <!--begin::Wrapper-->
        <div class="d-flex flex-center">
          <!--begin::Items-->
          <div class="d-flex flex-md-row flex-column justify-content-between mx-auto w-xl-900px">
            <!--begin::Item-->
            <div class="octagon d-flex flex-center h-200px w-200px bg-body mx-2 mb-10">
              <!--begin::Content-->
              <div class="text-center">
                <!--begin::Symbol-->
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <i class="bi bi-coin fs-4x text-warning"></i>
                <!--end::Svg Icon-->
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="mt-1">
                  <!--begin::Animation-->
                  <div class="fs-lg-2hx fs-2x fw-bolder text-gray-800 d-flex align-items-center">
                    <!--                    <div class="min-w-70px" data-kt-countup="true" data-kt-countup-value="700">0</div>-->
                    <div class="min-w-50px" [countUp]="count_all_coins" #el_count_all_coins></div>
                    <!--                    +-->
                  </div>
                  <!--end::Animation-->
                  <!--begin::Label-->
                  <span class="text-gray-600 fw-bold fs-5 lh-0">{{'US.COIN' | translate}}</span>
                  <!--end::Label-->
                </div>
                <!--end::Text-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="octagon d-flex flex-center h-200px w-200px bg-body mx-2 mb-10">
              <!--begin::Content-->
              <div class="text-center">
                <!--begin::Symbol-->
                <!--begin::Svg Icon | path: icons/duotune/graphs/gra008.svg-->
                <i class="bi bi-clipboard-data text-success fs-4x"></i>
                <!--end::Svg Icon-->
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="mt-1">
                  <!--begin::Animation-->
                  <div class="fs-lg-2hx fs-2x fw-bolder text-gray-800 d-flexx align-items-centerr">
                    <!--                    <div class="min-w-50px" data-kt-countup="true" data-kt-countup-value="80">0</div>-->
                    <!--                    <div class="min-w-50px" [countUp]="coun" #counte></div>-->
                    <div class="min-w-50px" [countUp]="count_all_rankshots" #el_count_all_rankshots></div>
                    <!--                    K+-->
                  </div>
                  <!--end::Animation-->
                  <!--begin::Label-->
                  <span class="text-gray-600 fw-bold fs-5 lh-0">{{'US.ANALYSIS' | translate}}</span>
                  <!--end::Label-->
                </div>
                <!--end::Text-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="octagon d-flex flex-center h-200px w-200px bg-body mx-2 mb-10">
              <!--begin::Content-->
              <div class="text-center">
                <!--begin::Symbol-->
                <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg-->
                <i class="bi bi-person-bounding-box text-info fs-4x"></i>
                <!--end::Svg Icon-->
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="mt-1">
                  <!--begin::Animation-->
                  <div class="fs-lg-2hx fs-2x fw-bolder text-gray-800 d-flex align-items-center">
                    <div class="min-w-50px" [countUp]="count_all_users" #el_count_all_users></div>
                    <!--                    M+-->
                  </div>
                  <!--end::Animation-->
                  <!--begin::Label-->
                  <span class="text-gray-600 fw-bold fs-5 lh-0">{{'US.CUSTOMER' | translate}}</span>
                  <!--end::Label-->
                </div>
                <!--end::Text-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Item-->
          </div>
          <!--end::Items-->
        </div>
        <!--end::Wrapper-->
        <!--begin::Testimonial-->
        <div class="fs-2 fw-bold text-muted text-center mb-3">
          <!--          <span class="fs-1 lh-1 text-gray-700">“</span>When you care about your topic, you’ll write about it in a-->
          <!--          <br/>-->
          <!--          <span class="text-gray-700 me-1">more powerful</span>, emotionally expressive way-->
          <!--          <span class="fs-1 lh-1 text-gray-700">“</span>-->
          <p class="mb-8" translate="US.P06"></p>
        </div>
        <!--end::Testimonial-->
        <!--begin::Author-->
        <div class="fs-2 fw-bold text-muted text-center">
          <span class="fs-4 fw-bolder text-gray-600">{{'GENERAL.HASHTCOIN' | translate}}, </span>
          <a href="/metronic8/demo1/../demo1/account/security.html"
             class="link-primary fs-4 fw-bolder">{{'GENERAL.WEBNESHIN' | translate}}</a>
        </div>
        <!--end::Author-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Statistics-->
    <!--begin::Section-->
    <div class="mb-16">
      <!--begin::Top-->
      <div class="text-center mb-12">
        <!--begin::Title-->
        <h3 class="fs-2hx text-dark mb-5">{{'US.SERVICES.TITLE' | translate}}</h3>
        <!--end::Title-->
        <!--begin::Text-->
        <div class="fs-5 text-muted fw-bold">
          {{'US.SERVICES.DESCRIPTION01' | translate}}<br/>
          {{'US.SERVICES.DESCRIPTION02' | translate}}
        </div>
        <!--end::Text-->
      </div>
      <!--end::Top-->
      <!--begin::Row-->
      <div class="row g-10 ">
        <!--begin::Col-->
        <div class="col-md-6 col-xl-4 mb-10 mb-md-0">
          <!--begin::Publications post-->
          <div class="card-xl-stretch me-md-6">
            <!--begin::Overlay-->
            <a class="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales"
               href="assets/media/about/data-analysis.jpg">
              <!--begin::Image-->
              <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                   style="background-image:url('assets/media/about/data-analysis.jpg')"></div>
              <!--end::Image-->
              <!--begin::Action-->
              <div class="overlay-layer bg-dark card-rounded bg-opacity-25">
                <i class="bi bi-eye-fill fs-2x text-white"></i>
              </div>
              <!--end::Action-->
            </a>
            <!--end::Overlay-->
            <!--begin::Body-->
            <div class="m-0">
              <!--begin::Title-->
              <a class="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">
                {{'US.SERVICES.S01.TITLE' | translate}}
              </a>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5" style="text-align: justify">
                {{'US.SERVICES.S01.DESCRIPTION' | translate}}
              </div>
              <!--end::Text-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Publications post-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-md-6 col-xl-4 mb-10 mb-md-0">
          <!--begin::Publications post-->
          <div class="card-xl-stretch me-md-6">
            <!--begin::Overlay-->
            <a class="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales"
               href="assets/media/about/programming.jpg">
              <!--begin::Image-->
              <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                   style="background-image:url('assets/media/about/programming.jpg')"></div>
              <!--end::Image-->
              <!--begin::Action-->
              <div class="overlay-layer bg-dark card-rounded bg-opacity-25">
                <i class="bi bi-eye-fill fs-2x text-white"></i>
              </div>
              <!--end::Action-->
            </a>
            <!--end::Overlay-->
            <!--begin::Body-->
            <div class="m-0">
              <!--begin::Title-->
              <a class="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">
                {{'US.SERVICES.S02.TITLE' | translate}}
              </a>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5" style="text-align: justify">
                {{'US.SERVICES.S02.DESCRIPTION' | translate}}
              </div>
              <!--end::Text-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Publications post-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-md-6 col-xl-4 mb-10 mb-md-0">
          <!--begin::Publications post-->
          <div class="card-xl-stretch me-md-6">
            <!--begin::Overlay-->
            <a class="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales"
               href="assets/media/about/chand-chabd.png">
              <!--begin::Image-->
              <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"
                   style="background-image:url('assets/media/about/chand-chabd.png')"></div>
              <!--end::Image-->
              <!--begin::Action-->
              <div class="overlay-layer bg-dark card-rounded bg-opacity-25">
                <i class="bi bi-eye-fill fs-2x text-white"></i>
              </div>
              <!--end::Action-->
            </a>
            <!--end::Overlay-->
            <!--begin::Body-->
            <div class="m-0">
              <!--begin::Title-->
              <a class="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">
                {{'US.SERVICES.S03.TITLE' | translate}}
              </a>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5" style="text-align: justify">
                {{'US.SERVICES.S03.DESCRIPTION' | translate}}
              </div>
              <!--end::Text-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Publications post-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <!--        <div class="col-md-4">-->
        <!--          &lt;!&ndash;begin::Publications post&ndash;&gt;-->
        <!--          <div class="card-xl-stretch mx-md-3">-->
        <!--            &lt;!&ndash;begin::Overlay&ndash;&gt;-->
        <!--            <a class="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales"-->
        <!--               href="assets/media/stock/600x400/img-74.jpg">-->
        <!--              &lt;!&ndash;begin::Image&ndash;&gt;-->
        <!--              <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"-->
        <!--                   style="background-image:url('assets/media/stock/600x400/img-74.jpg')"></div>-->
        <!--              &lt;!&ndash;end::Image&ndash;&gt;-->
        <!--              &lt;!&ndash;begin::Action&ndash;&gt;-->
        <!--              <div class="overlay-layer bg-dark card-rounded bg-opacity-25">-->
        <!--                <i class="bi bi-eye-fill fs-2x text-white"></i>-->
        <!--              </div>-->
        <!--              &lt;!&ndash;end::Action&ndash;&gt;-->
        <!--            </a>-->
        <!--            &lt;!&ndash;end::Overlay&ndash;&gt;-->
        <!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
        <!--            <div class="m-0">-->
        <!--              &lt;!&ndash;begin::Title&ndash;&gt;-->
        <!--              <a href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"-->
        <!--                 class="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">Admin Panel - How To Started the-->
        <!--                Dashboard Tutorial</a>-->
        <!--              &lt;!&ndash;end::Title&ndash;&gt;-->
        <!--              &lt;!&ndash;begin::Text&ndash;&gt;-->
        <!--              <div class="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5">We’ve been focused on making the from v4 to v5-->
        <!--                but we have also not been afraid to step away been focused-->
        <!--              </div>-->
        <!--              &lt;!&ndash;end::Text&ndash;&gt;-->
        <!--              &lt;!&ndash;begin::Content&ndash;&gt;-->
        <!--              <div class="fs-6 fw-bolder">-->
        <!--                &lt;!&ndash;begin::Author&ndash;&gt;-->
        <!--                <a href="/metronic8/demo1/../demo1/apps/projects/users.html" class="text-gray-700 text-hover-primary">Cris-->
        <!--                  Morgan</a>-->
        <!--                &lt;!&ndash;end::Author&ndash;&gt;-->
        <!--                &lt;!&ndash;begin::Date&ndash;&gt;-->
        <!--                <span class="text-muted">on Apr 14 2021</span>-->
        <!--                &lt;!&ndash;end::Date&ndash;&gt;-->
        <!--              </div>-->
        <!--              &lt;!&ndash;end::Content&ndash;&gt;-->
        <!--            </div>-->
        <!--            &lt;!&ndash;end::Body&ndash;&gt;-->
        <!--          </div>-->
        <!--          &lt;!&ndash;end::Publications post&ndash;&gt;-->
        <!--        </div>-->
        <!--end::Col-->
        <!--begin::Col-->
        <!--        <div class="col-md-4">-->
        <!--          &lt;!&ndash;begin::Publications post&ndash;&gt;-->
        <!--          <div class="card-xl-stretch ms-md-6">-->
        <!--            &lt;!&ndash;begin::Overlay&ndash;&gt;-->
        <!--            <a class="d-block overlay mb-4" data-fslightbox="lightbox-hot-sales"-->
        <!--               href="assets/media/stock/600x400/img-47.jpg">-->
        <!--              &lt;!&ndash;begin::Image&ndash;&gt;-->
        <!--              <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px"-->
        <!--                   style="background-image:url('assets/media/stock/600x400/img-47.jpg')"></div>-->
        <!--              &lt;!&ndash;end::Image&ndash;&gt;-->
        <!--              &lt;!&ndash;begin::Action&ndash;&gt;-->
        <!--              <div class="overlay-layer bg-dark card-rounded bg-opacity-25">-->
        <!--                <i class="bi bi-eye-fill fs-2x text-white"></i>-->
        <!--              </div>-->
        <!--              &lt;!&ndash;end::Action&ndash;&gt;-->
        <!--            </a>-->
        <!--            &lt;!&ndash;end::Overlay&ndash;&gt;-->
        <!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
        <!--            <div class="m-0">-->
        <!--              &lt;!&ndash;begin::Title&ndash;&gt;-->
        <!--              <a href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"-->
        <!--                 class="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">Admin Panel - How To Started the-->
        <!--                Dashboard Tutorial</a>-->
        <!--              &lt;!&ndash;end::Title&ndash;&gt;-->
        <!--              &lt;!&ndash;begin::Text&ndash;&gt;-->
        <!--              <div class="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5">We’ve been focused on making the from v4 to v5-->
        <!--                but we’ve also not been afraid to step away been focused-->
        <!--              </div>-->
        <!--              &lt;!&ndash;end::Text&ndash;&gt;-->
        <!--              &lt;!&ndash;begin::Content&ndash;&gt;-->
        <!--              <div class="fs-6 fw-bolder">-->
        <!--                &lt;!&ndash;begin::Author&ndash;&gt;-->
        <!--                <a href="/metronic8/demo1/../demo1/apps/projects/users.html" class="text-gray-700 text-hover-primary">Carles-->
        <!--                  Nilson</a>-->
        <!--                &lt;!&ndash;end::Author&ndash;&gt;-->
        <!--                &lt;!&ndash;begin::Date&ndash;&gt;-->
        <!--                <span class="text-muted">on May 14 2021</span>-->
        <!--                &lt;!&ndash;end::Date&ndash;&gt;-->
        <!--              </div>-->
        <!--              &lt;!&ndash;end::Content&ndash;&gt;-->
        <!--            </div>-->
        <!--            &lt;!&ndash;end::Body&ndash;&gt;-->
        <!--          </div>-->
        <!--          &lt;!&ndash;end::Publications post&ndash;&gt;-->
        <!--        </div>-->
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Section-->
    <!--begin::Team-->
    <!--    <div class="mb-18">-->
    <!--      &lt;!&ndash;begin::Heading&ndash;&gt;-->
    <!--      <div class="text-center mb-12">-->
    <!--        &lt;!&ndash;begin::Title&ndash;&gt;-->
    <!--        <h3 class="fs-2hx text-dark mb-5">Our Great Team</h3>-->
    <!--        &lt;!&ndash;end::Title&ndash;&gt;-->
    <!--        &lt;!&ndash;begin::Sub-title&ndash;&gt;-->
    <!--        <div class="fs-5 text-muted fw-bold">It’s no doubt that when a development takes longer to complete, additional-->
    <!--          costs to-->
    <!--          <br/>integrate and test each extra feature creeps up and haunts most of us.-->
    <!--        </div>-->
    <!--        &lt;!&ndash;end::Sub-title=&ndash;&gt;-->
    <!--      </div>-->
    <!--      &lt;!&ndash;end::Heading&ndash;&gt;-->
    <!--      &lt;!&ndash;begin::Slider&ndash;&gt;-->
    <!--      <div class="tns tns-default mb-10">-->
    <!--        &lt;!&ndash;begin::Wrapper&ndash;&gt;-->
    <!--        <div data-tns="true" data-tns-loop="true" data-tns-swipe-angle="false" data-tns-speed="2000"-->
    <!--             data-tns-autoplay="true" data-tns-autoplay-timeout="18000" data-tns-controls="true" data-tns-nav="false"-->
    <!--             data-tns-items="1" data-tns-center="false" data-tns-dots="false"-->
    <!--             data-tns-prev-button="#kt_team_slider_prev" data-tns-next-button="#kt_team_slider_next"-->
    <!--             data-tns-responsive="{1200: {items: 3}, 992: {items: 2}}">-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-1.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Paul Miles</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">Development Lead</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-2.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Melisa Marcus</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">Creative Director</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-5.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">David Nilson</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">Python Expert</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-20.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Anne Clarc</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">Project Manager</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-10.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Alice Wayde</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">Marketing Manager</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--          &lt;!&ndash;begin::Item&ndash;&gt;-->
    <!--          <div class="text-center">-->
    <!--            &lt;!&ndash;begin::Photo&ndash;&gt;-->
    <!--            <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
    <!--                 style="background-image:url('assets/media/avatars/150-11.jpg')"></div>-->
    <!--            &lt;!&ndash;end::Photo&ndash;&gt;-->
    <!--            &lt;!&ndash;begin::Person&ndash;&gt;-->
    <!--            <div class="mb-0">-->
    <!--              &lt;!&ndash;begin::Name&ndash;&gt;-->
    <!--              <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Carles Puyol</a>-->
    <!--              &lt;!&ndash;end::Name&ndash;&gt;-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--              <div class="text-muted fs-6 fw-bold mt-1">QA Managers</div>-->
    <!--              &lt;!&ndash;begin::Position&ndash;&gt;-->
    <!--            </div>-->
    <!--            &lt;!&ndash;end::Person&ndash;&gt;-->
    <!--          </div>-->
    <!--          &lt;!&ndash;end::Item&ndash;&gt;-->
    <!--        </div>-->
    <!--        &lt;!&ndash;end::Wrapper&ndash;&gt;-->
    <!--        &lt;!&ndash;begin::Button&ndash;&gt;-->
    <!--        <button class="btn btn-icon btn-active-color-primary" id="kt_team_slider_prev">-->
    <!--          &lt;!&ndash;begin::Svg Icon | path: icons/duotune/arrows/arr074.svg&ndash;&gt;-->
    <!--          <span class="svg-icon svg-icon-3x">-->
    <!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
    <!--              <path-->
    <!--                d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z"-->
    <!--                fill="black"/>-->
    <!--            </svg>-->
    <!--          </span>-->
    <!--          &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
    <!--        </button>-->
    <!--        &lt;!&ndash;end::Button&ndash;&gt;-->
    <!--        &lt;!&ndash;begin::Button&ndash;&gt;-->
    <!--        <button class="btn btn-icon btn-active-color-primary" id="kt_team_slider_next">-->
    <!--          &lt;!&ndash;begin::Svg Icon | path: icons/duotune/arrows/arr071.svg&ndash;&gt;-->
    <!--          <span class="svg-icon svg-icon-3x">-->
    <!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
    <!--              <path-->
    <!--                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"-->
    <!--                fill="black"/>-->
    <!--            </svg>-->
    <!--          </span>-->
    <!--          &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
    <!--        </button>-->
    <!--        &lt;!&ndash;end::Button&ndash;&gt;-->
    <!--      </div>-->
    <!--      &lt;!&ndash;end::Slider&ndash;&gt;-->
    <!--    </div>-->
    <!--end::Team-->
    <!--begin::Card-->
    <div class="card mb-4 bg-light text-center">
      <!--begin::Body-->
      <div class="card-body py-12">
        <!--begin::Icon-->
        <a href="https://t.me/hashtcoin" class="mx-4" target="_blank">
          <img src="assets/media/svg/brand-logos/telegram.svg" class="h-30px my-2" alt=""/>
        </a>
        <a href="mailto:sajjad.eb@gmail.com" class="mx-4" target="_blank">
          <img src="assets/media/svg/brand-logos/google-icon.svg" class="h-30px my-2" alt=""/>
        </a>
        <!--end::Icon-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
  <!--end::Body-->
</div>
<!--end::About card-->
<!--<swiper-->
<!--  [slidesPerView]="3"-->
<!--  [spaceBetween]="50"-->
<!--  [autoplay]="true"-->
<!--  [pagination]="{ clickable: true }"-->
<!--  [scrollbar]="{ draggable: true }"-->
<!--&gt;-->

<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->
<!--  <ng-template swiperSlide>-->
<!--    &lt;!&ndash;begin::Item&ndash;&gt;-->
<!--    <div class="text-center">-->
<!--      &lt;!&ndash;begin::Photo&ndash;&gt;-->
<!--      <div class="octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"-->
<!--           style="background-image:url('assets/media/avatars/150-7.jpg')"></div>-->
<!--      &lt;!&ndash;end::Photo&ndash;&gt;-->
<!--      &lt;!&ndash;begin::Person&ndash;&gt;-->
<!--      <div class="mb-0">-->
<!--        &lt;!&ndash;begin::Name&ndash;&gt;-->
<!--        <a href="#" class="text-dark fw-bolder text-hover-primary fs-3">Ricky Hunt</a>-->
<!--        &lt;!&ndash;end::Name&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--        <div class="text-muted fs-6 fw-bold mt-1">Art Director</div>-->
<!--        &lt;!&ndash;begin::Position&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Person&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash;end::Item&ndash;&gt;-->
<!--  </ng-template>-->

<!--</swiper>-->

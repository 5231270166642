/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { UserCreatePasswordRetype } from '../models/user-create-password-retype';
import { Activation } from '../models/activation';
import { SendEmailReset } from '../models/send-email-reset';
import { UsernameResetConfirm } from '../models/username-reset-confirm';
import { PasswordResetConfirmRetype } from '../models/password-reset-confirm-retype';
import { SetUsername } from '../models/set-username';
import { SetPassword } from '../models/set-password';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly usersListPath = '/users/';
  static readonly usersCreatePath = '/users/';
  static readonly usersActivationPath = '/users/activation/';
  static readonly usersMeReadPath = '/users/me/';
  static readonly usersMeUpdatePath = '/users/me/';
  static readonly usersMePartialUpdatePath = '/users/me/';
  static readonly usersMeDeletePath = '/users/me/';
  static readonly usersResendActivationPath = '/users/resend_activation/';
  static readonly usersResetUsernamePath = '/users/reset_email/';
  static readonly usersResetUsernameConfirmPath = '/users/reset_email_confirm/';
  static readonly usersResetPasswordPath = '/users/reset_password/';
  static readonly usersResetPasswordConfirmPath = '/users/reset_password_confirm/';
  static readonly usersSetUsernamePath = '/users/set_email/';
  static readonly usersSetPasswordPath = '/users/set_password/';
  static readonly usersReadPath = '/users/{id}/';
  static readonly usersUpdatePath = '/users/{id}/';
  static readonly usersPartialUpdatePath = '/users/{id}/';
  static readonly usersDeletePath = '/users/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UsersService.UsersListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  usersListResponse(params: UsersService.UsersListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<User>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<User>}>;
      })
    );
  }
  /**
   * @param params The `UsersService.UsersListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  usersList(params: UsersService.UsersListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<User>}> {
    return this.usersListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<User>})
    );
  }

  /**
   * @param data undefined
   */
  usersCreateResponse(data: UserCreatePasswordRetype): __Observable<__StrictHttpResponse<UserCreatePasswordRetype>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserCreatePasswordRetype>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersCreate(data: UserCreatePasswordRetype): __Observable<UserCreatePasswordRetype> {
    return this.usersCreateResponse(data).pipe(
      __map(_r => _r.body as UserCreatePasswordRetype)
    );
  }

  /**
   * @param data undefined
   */
  usersActivationResponse(data: Activation): __Observable<__StrictHttpResponse<Activation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/activation/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Activation>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersActivation(data: Activation): __Observable<Activation> {
    return this.usersActivationResponse(data).pipe(
      __map(_r => _r.body as Activation)
    );
  }

  /**
   * @param params The `UsersService.UsersMeReadParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  usersMeReadResponse(params: UsersService.UsersMeReadParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<User>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<User>}>;
      })
    );
  }
  /**
   * @param params The `UsersService.UsersMeReadParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  usersMeRead(params: UsersService.UsersMeReadParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<User>}> {
    return this.usersMeReadResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<User>})
    );
  }

  /**
   * @param data undefined
   */
  usersMeUpdateResponse(data: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersMeUpdate(data: User): __Observable<User> {
    return this.usersMeUpdateResponse(data).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param data undefined
   */
  usersMePartialUpdateResponse(data: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersMePartialUpdate(data: User): __Observable<User> {
    return this.usersMePartialUpdateResponse(data).pipe(
      __map(_r => _r.body as User)
    );
  }
  usersMeDeleteResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/me/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  usersMeDelete(): __Observable<null> {
    return this.usersMeDeleteResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  usersResendActivationResponse(data: SendEmailReset): __Observable<__StrictHttpResponse<SendEmailReset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/resend_activation/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendEmailReset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersResendActivation(data: SendEmailReset): __Observable<SendEmailReset> {
    return this.usersResendActivationResponse(data).pipe(
      __map(_r => _r.body as SendEmailReset)
    );
  }

  /**
   * @param data undefined
   */
  usersResetUsernameResponse(data: SendEmailReset): __Observable<__StrictHttpResponse<SendEmailReset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/reset_email/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendEmailReset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersResetUsername(data: SendEmailReset): __Observable<SendEmailReset> {
    return this.usersResetUsernameResponse(data).pipe(
      __map(_r => _r.body as SendEmailReset)
    );
  }

  /**
   * @param data undefined
   */
  usersResetUsernameConfirmResponse(data: UsernameResetConfirm): __Observable<__StrictHttpResponse<UsernameResetConfirm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/reset_email_confirm/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsernameResetConfirm>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersResetUsernameConfirm(data: UsernameResetConfirm): __Observable<UsernameResetConfirm> {
    return this.usersResetUsernameConfirmResponse(data).pipe(
      __map(_r => _r.body as UsernameResetConfirm)
    );
  }

  /**
   * @param data undefined
   */
  usersResetPasswordResponse(data: SendEmailReset): __Observable<__StrictHttpResponse<SendEmailReset>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/reset_password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendEmailReset>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersResetPassword(data: SendEmailReset): __Observable<SendEmailReset> {
    return this.usersResetPasswordResponse(data).pipe(
      __map(_r => _r.body as SendEmailReset)
    );
  }

  /**
   * @param data undefined
   */
  usersResetPasswordConfirmResponse(data: PasswordResetConfirmRetype): __Observable<__StrictHttpResponse<PasswordResetConfirmRetype>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/reset_password_confirm/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PasswordResetConfirmRetype>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersResetPasswordConfirm(data: PasswordResetConfirmRetype): __Observable<PasswordResetConfirmRetype> {
    return this.usersResetPasswordConfirmResponse(data).pipe(
      __map(_r => _r.body as PasswordResetConfirmRetype)
    );
  }

  /**
   * @param data undefined
   */
  usersSetUsernameResponse(data: SetUsername): __Observable<__StrictHttpResponse<SetUsername>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/set_email/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetUsername>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersSetUsername(data: SetUsername): __Observable<SetUsername> {
    return this.usersSetUsernameResponse(data).pipe(
      __map(_r => _r.body as SetUsername)
    );
  }

  /**
   * @param data undefined
   */
  usersSetPasswordResponse(data: SetPassword): __Observable<__StrictHttpResponse<SetPassword>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/set_password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetPassword>;
      })
    );
  }
  /**
   * @param data undefined
   */
  usersSetPassword(data: SetPassword): __Observable<SetPassword> {
    return this.usersSetPasswordResponse(data).pipe(
      __map(_r => _r.body as SetPassword)
    );
  }

  /**
   * @param id A unique integer value identifying this user.
   */
  usersReadResponse(id: number): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this user.
   */
  usersRead(id: number): __Observable<User> {
    return this.usersReadResponse(id).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UsersService.UsersUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  usersUpdateResponse(params: UsersService.UsersUpdateParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `UsersService.UsersUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  usersUpdate(params: UsersService.UsersUpdateParams): __Observable<User> {
    return this.usersUpdateResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param params The `UsersService.UsersPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  usersPartialUpdateResponse(params: UsersService.UsersPartialUpdateParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/users/${encodeURIComponent(String(params.id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * @param params The `UsersService.UsersPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this user.
   *
   * - `data`:
   */
  usersPartialUpdate(params: UsersService.UsersPartialUpdateParams): __Observable<User> {
    return this.usersPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * @param id A unique integer value identifying this user.
   */
  usersDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this user.
   */
  usersDelete(id: number): __Observable<null> {
    return this.usersDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UsersService {

  /**
   * Parameters for usersList
   */
  export interface UsersListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
  }

  /**
   * Parameters for usersMeRead
   */
  export interface UsersMeReadParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
  }

  /**
   * Parameters for usersUpdate
   */
  export interface UsersUpdateParams {

    /**
     * A unique integer value identifying this user.
     */
    id: number;
    data: User;
  }

  /**
   * Parameters for usersPartialUpdate
   */
  export interface UsersPartialUpdateParams {

    /**
     * A unique integer value identifying this user.
     */
    id: number;
    data: User;
  }
}

export { UsersService }

<!--<div class="card mb-10 p-10">-->
<!--  <h4 class="fs-2x text-gray-800 w-bolder  mb-6">-->
<!--    {{'WALLET.MENU_TITLE' | translate}}-->
<!--  </h4>-->
<!--  <p class="fw-bold fs-4 text-gray-600 mb-2">-->
<!--    {{'HELP.DESCRIPTION' | translate}}-->
<!--  </p>-->
<!--</div>-->

<div class="alert alert-warning" role="alert">
  <i class="bi bi-exclamation-triangle fs-5 me-3 ms-0 text-warning" ></i>
  {{'WALLET.DESCRIPTION1' | translate}}
</div>
<div class="alert alert-warning" role="alert">
  <i class="bi bi-exclamation-triangle fs-5 me-3 ms-0 text-warning" ></i>
  {{'WALLET.DESCRIPTION2' | translate}}
</div>
<div class="alert alert-primary" role="alert">
  <i class="bi bi-send fs-5 me-3 ms-0 text-primary" ></i>
  {{'WALLET.DESCRIPTION3' | translate}}
</div>

<div class="row" [style.direction]="'ltr'" [style.font-family]="'arial'">
  <app-wallet
    class="col-xl-6"
    address="TWEhVVcjXWFfMVsvGV46vedwJC7R11UNUD"
    name="Tether"
    symbol="USDT"
    network="TRC20"
  ></app-wallet>
</div>

<!-- todo: ijad form daryaft etelaat-->

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tradingview',
  templateUrl: './tradingview.component.html',
  styleUrls: ['./tradingview.component.scss']
})
export class TradingviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

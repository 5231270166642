<div *ngIf="!(analysis$ | async)" class="h-100 d-flex justify-content-center ">
  <div class=" w-200px align-self-center card">
    <div class="card-body p-9 d-flex flex-column">
      <div class="spinner-grow text-success mx-auto" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="mx-auto ">
        <p class="text-justify">{{'RANK_ANALYSIS.WAIT' | translate}}</p>
      </div>
    </div>
  </div>
</div>
<!--<div *ngFor="let a of (analysis$ | async)?.new_in_top_100">-->
<!--  <span>{{a.coin_name}}</span>-->
<!--</div>-->

<!--begin::Stats-->
<div *ngIf="analysis$ | async as analysis" class="row g-6 g-xl-9">
  <div class="col-12 col-sm-9 col-md-6 col-xl-4 mx-auto">
    <!--begin::Card-->
    <div class="card h-100">
      <!--begin::Card body-->
      <div class="card-body p-9">
        <!--begin::Heading-->
        <div class="fs-2hx fw-bolder">{{analysis.info.count_all_ranks_coins}}</div>
        <div class="fs-4 fw-bold text-gray-400 mb-7">{{'RANK_ANALYSIS.COUNT_ALL_RANKS_COINS' | translate}}</div>
        <!--end::Heading-->
        <!--begin::Wrapper-->
        <div class="d-flex flex-wrap">
          <!--begin::Chart-->
          <!--          <div class="d-flex flex-center h-100px w-100px me-9 mb-5">-->
          <!--            <canvas id="kt_project_list_chart"></canvas>-->
          <!--          </div>-->
          <div id="kt_charts_widget_1_chart">
            <apx-chart
              [series]="[
              analysis.info.count_new_ranks_coins,
              analysis.info.count_old_ranks_coins,
              ]"
              [labels]="chartOptions.labels"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [legend]="chartOptions.legend"
              [plotOptions]="chartOptions.plotOptions"
            ></apx-chart>
          </div>

          <!--end::Chart-->
          <!--begin::Labels-->
          <!--          <div class="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">-->
          <!--            &lt;!&ndash;begin::Label&ndash;&gt;-->
          <!--            <div class="d-flex fs-6 fw-bold align-items-center mb-3">-->
          <!--              <div class="bullet bg-primary me-3"></div>-->
          <!--              <div class="text-gray-400">Active</div>-->
          <!--              <div class="ms-auto fw-bolder text-gray-700">30</div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;end::Label&ndash;&gt;-->
          <!--            &lt;!&ndash;begin::Label&ndash;&gt;-->
          <!--            <div class="d-flex fs-6 fw-bold align-items-center mb-3">-->
          <!--              <div class="bullet bg-success me-3"></div>-->
          <!--              <div class="text-gray-400">Completed</div>-->
          <!--              <div class="ms-auto fw-bolder text-gray-700">45</div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;end::Label&ndash;&gt;-->
          <!--            &lt;!&ndash;begin::Label&ndash;&gt;-->
          <!--            <div class="d-flex fs-6 fw-bold align-items-center">-->
          <!--              <div class="bullet bg-gray-300 me-3"></div>-->
          <!--              <div class="text-gray-400">Yet to start</div>-->
          <!--              <div class="ms-auto fw-bolder text-gray-700">25</div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;end::Label&ndash;&gt;-->
          <!--          </div>-->
          <!--end::Labels-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
  <div class="col-12 col-sm-9 col-md-6 col-xl-4 mx-auto">
    <!--begin::Budget-->
    <div class="card h-100">
      <div class="card-body p-9">
        <div class="fs-2hx fw-bolder">
          <!--          {{analysis.info.delta_new_old_time}}-->
          {{duration}}
        </div>
        <div class="fs-4 fw-bold text-gray-400">{{'RANK_ANALYSIS.DELTA_NEW_OLD_TIME' | translate}}</div>
        <div class="separator separator-dashed my-2"></div>
        <div class="fs-6 fw-bold text-gray-400 mb-7">
          {{'RANK_ANALYSIS.UPDATE' | translate}} {{updated}}
        </div>

        <!--        <div class="fs-6 d-flex justify-content-between mb-4">-->
        <!--          <div class="fw-bold">{{'RANK_ANALYSIS.NEW_RANK_SHOT_TIME' | translate}}</div>-->
        <!--          <div class="d-flex fw-bolder" >-->
        <!--            {{'GENERAL.FOR' | translate}} {{analysis.info.update_in }} {{'GENERAL.AGO' | translate}}-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="separator separator-dashed"></div>-->

        <div class="fs-6 d-flex justify-content-between mb-4">
          <div class="fw-bold">{{'RANK_ANALYSIS.NEW_RANK_SHOT_TIME' | translate}}</div>
          <div class="d-flex fw-bolder" [style.direction]="'ltr'">
            {{analysis.info.new_rank_shot_time | date : 'yyyy/MM/dd HH:mm' }}
          </div>
        </div>

        <div class="separator separator-dashed"></div>

        <div class="fs-6 d-flex justify-content-between my-4">
          <div class="fw-bold">{{'RANK_ANALYSIS.OLD_RANK_SHOT_TIME' | translate}}</div>
          <div class="d-flex fw-bolder" [style.direction]="'ltr'">
            {{analysis.info.old_rank_shot_time | date:'yyyy/MM/dd HH:mm'}}
          </div>
        </div>
        <!--        <div class="separator separator-dashed"></div>-->
        <!--        <div class="fs-6 d-flex justify-content-between mt-4">-->
        <!--          <div class="fw-bold">Ambassador Page</div>-->
        <!--          <div class="d-flex fw-bolder">-->
        <!--            &lt;!&ndash;begin::Svg Icon | path: icons/duotune/arrows/arr007.svg&ndash;&gt;-->
        <!--            <span class="svg-icon svg-icon-3 me-1 svg-icon-success">-->
        <!--														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"-->
        <!--                                 fill="none">-->
        <!--															<path-->
        <!--                                d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z"-->
        <!--                                fill="black"/>-->
        <!--															<path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z" fill="black"/>-->
        <!--														</svg>-->
        <!--													</span>-->
        <!--            &lt;!&ndash;end::Svg Icon&ndash;&gt;$920-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <!--end::Budget-->
  </div>
  <div class="col-12 col-sm-9 col-md-6 col-xl-4 mx-auto">
    <!--begin::Clients-->
    <div class="card h-100">
      <div class="card-body p-9">
        <!--begin::Heading-->
        <div class="fs-2hx fw-bolder">{{analysis.new_coins.count_new_coins}}</div>
        <div class="fs-4 fw-bold text-gray-400 mb-7">{{'RANK_ANALYSIS.NEW_COINS_IN_LAST_RANK_SHOT' | translate}}</div>
        <!--end::Heading-->
        <!--begin::Users group-->
        <div class="symbol-group symbol-hover mb-9">
          <div *ngFor="let c of analysis.new_coins.top_new_coins"
               class="symbol symbol-35px symbol-circle"
               data-bs-toggle="tooltip"
               [title]="c.coin_name">
            <a [routerLink]="['/coins/'+c.coin_id+'/ranking']">
              <span *ngIf="c.coin_logo == ''" class="symbol-label bg-warning text-inverse-warning fw-bolder">
                {{c.coin_name | slice:0:1}}
              </span>
              <img *ngIf="c.coin_logo != ''"
                   [alt]="c.coin_name"
                   height="35px" width="35px"
                   [src]="this.rootUrl +c.coin_logo"/>
            </a>
          </div>
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Michael Eberon">-->
          <!--            <img alt="Pic" src="/metronic8/demo1/assets/media/avatars/300-11.jpg"/>-->
          <!--          </div>-->
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Michelle Swanston">-->
          <!--            <img alt="Pic" src="/metronic8/demo1/assets/media/avatars/300-7.jpg"/>-->
          <!--          </div>-->
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Francis Mitcham">-->
          <!--            <img alt="Pic" src="/metronic8/demo1/assets/media/avatars/300-20.jpg"/>-->
          <!--          </div>-->
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">-->
          <!--            <span class="symbol-label bg-primary text-inverse-primary fw-bolder">S</span>-->
          <!--          </div>-->
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Melody Macy">-->
          <!--            <img alt="Pic" src="/metronic8/demo1/assets/media/avatars/300-2.jpg"/>-->
          <!--          </div>-->
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Perry Matthew">-->
          <!--            <span class="symbol-label bg-info text-inverse-info fw-bolder">P</span>-->
          <!--          </div>-->
          <!--          <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Barry Walter">-->
          <!--            <img alt="Pic" src="/metronic8/demo1/assets/media/avatars/300-12.jpg"/>-->
          <!--          </div>-->
          <a href="#" class="symbol symbol-35px symbol-circle" data-bs-toggle="modal"
             data-bs-target="#kt_modal_view_users">
            <span *ngIf="analysis.new_coins.count_new_coins > analysis.new_coins.top_new_coins.length "
                  class="symbol-label bg-dark text-gray-300 fs-8 fw-bolder">
              {{analysis.new_coins.count_new_coins - analysis.new_coins.top_new_coins.length  }}
            </span>
          </a>
        </div>
        <!--end::Users group-->
        <!--begin::Actions-->
        <!--        <div class="d-flex">-->
        <!--          <a href="#" class="btn btn-primary btn-sm me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">All-->
        <!--            Clients</a>-->
        <!--          <a href="#" class="btn btn-light btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Invite-->
        <!--            New</a>-->
        <!--        </div>-->
        <!--end::Actions-->
      </div>
    </div>
    <!--end::Clients-->
  </div>


  <!--start:new top 100-->
  <div class="m-0">
    <div class="d-flex flex-wrap flex-stack mt-20 mb-6">
      <h3 class="fw-bolder my-2">
        {{'RANK_ANALYSIS.NEW_IN_TOP_100'  | translate}}
        <!--        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>-->
      </h3>
    </div>

    <div *ngIf="analysis.new_in_top_100.length < 1">
      {{'RANK_ANALYSIS.EMPTY_NEW_IN_TOP_100'  | translate}}
    </div>

    <div class="row g-6 g-xl-9">
      <div *ngFor="let c of analysis.new_in_top_100" class="col-12 col-sm-9 col-md-6 col-xl-4 mx-auto mx-md-0 ">
        <app-coin-analysis-rank
          [coin_id]="c.coin_id"
          [coin_name]="c.coin_name"
          [coin_symbol]="c.coin_symbol"
          [coin_logo]="this.rootUrl + c.coin_logo"
          [have_coin_logo]="c.coin_logo"
          [new_rank]="c.new_rank"
          [old_rank]="c.old_rank"
          [is_new]="c.is_new"
          [changed]="c.changed"
          [changedP]="c.changedP"

        ></app-coin-analysis-rank>
      </div>
    </div>
  </div>
  <!--end:new top 100-->


  <!--  start:top_10_most_growths-->
  <div class="d-flex flex-wrap flex-stack mt-20">
    <h3 class="fw-bolder my-2">
      {{'RANK_ANALYSIS.GROWTHS_IN_TOP_500'  | translate}}
      <!--        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>-->
    </h3>
  </div>
  <div class="row g-6 g-xl-9 mt-0">
    <!-- begin::Col -->
    <div class="col-xl-6">
      <div class="card card-xl-stretch mb-xl-8">
        <app-coin-analysis-table
          title="{{'RANK_ANALYSIS.TOP_20_COIN_SORT_BY_CHANGE_RANK'  | translate}}"
          description="{{'RANK_ANALYSIS.IN_FIRST_500'  | translate}}"
          [rows]="analysis.top_10_most_growths_in_top_500"
        ></app-coin-analysis-table>
      </div>
    </div>
    <!-- end::Col -->

    <!-- begin::Col -->
    <div class="col-xl-6">
      <div class="card card-xl-stretch mb-5 mb-xl-8">
        <app-coin-analysis-table
          title="{{'RANK_ANALYSIS.TOP_20_COIN_SORT_BY_CHANGE_PERCENT_RANK'  | translate}}"
          description="{{'RANK_ANALYSIS.IN_FIRST_500'  | translate}}"
          [rows]="analysis.top_10_most_growthsP_in_top_500"
        ></app-coin-analysis-table>
      </div>
    </div>
    <!-- end::Col -->
  </div>
  <!-- end::Row -->
  <!--  start:top_10_most_growths-->


  <!--  start:top_10_most_growths-->
  <div class="d-flex flex-wrap flex-stack mt-10">
    <h3 class="fw-bolder my-2">
      {{'RANK_ANALYSIS.GROWTHS_IN_ALL'  | translate}}
      <!--        <span class="fs-6 text-gray-400 fw-bold ms-1">Active</span>-->
    </h3>
  </div>
  <div class="row g-6 g-xl-9 mt-0">
    <!-- begin::Col -->
    <div class="col-xl-6">
      <div class="card card-xl-stretch mb-xl-8">
        <app-coin-analysis-table
          title="{{'RANK_ANALYSIS.TOP_20_COIN_SORT_BY_CHANGE_RANK'  | translate}}"
          description="{{'RANK_ANALYSIS.IN_ALL'  | translate}}"
          [rows]="analysis.top_10_most_growths"
        ></app-coin-analysis-table>
      </div>
    </div>
    <!-- end::Col -->

    <!-- begin::Col -->
    <div class="col-xl-6">
      <div class="card card-xl-stretch mb-5 mb-xl-8">
        <app-coin-analysis-table
          title="{{'RANK_ANALYSIS.TOP_20_COIN_SORT_BY_CHANGE_PERCENT_RANK'  | translate}}"
          description="{{'RANK_ANALYSIS.IN_ALL'  | translate}}"
          [rows]="analysis.top_10_most_growthsP"
        ></app-coin-analysis-table>
      </div>
    </div>
    <!-- end::Col -->
  </div>
  <!-- end::Row -->
  <!--  start:top_10_most_growths-->

</div>
<!--end::Stats-->

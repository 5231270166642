import {Component, OnInit} from '@angular/core';
import {UsersService} from "../../api/services/users.service";
import {Observable} from "rxjs";
import {PlansService} from "../../api/services/plans.service";
import {first, last, map, pluck, switchMap, take, tap} from "rxjs/operators";
import {SubscriptionPlan_} from "../../api/models/subscription-plan-_";
import {AuthService, UserType} from "../../modules/auth";
import {PaymentService} from "../../api/services/payment.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {TranslationService} from "../../modules/i18n";
import {TranslateService} from "@ngx-translate/core";
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-compare-subscribe',
  templateUrl: './compare-subscribe.component.html',
  styleUrls: ['./compare-subscribe.component.scss']
})
export class CompareSubscribeComponent implements OnInit {
  plans$: Observable<SubscriptionPlan_[]>
  plans: any
  duration: 'month' | 'year' = 'month'
  isAuthed = false;
  user$: Observable<UserType>;
  paymenting = false;


  constructor(
    private plansService: PlansService,
    private paymentService: PaymentService,
    private authService: AuthService,
    private translateService:TranslateService,
    private generalService:GeneralService,
  ) {
    generalService.Title({translate:true,titleString:'SUBSCRIBE.MENU_TITLE'})
  }

  ngOnInit(): void {
    this.user$ = this.authService.currentUserSubject.asObservable();
    if (this.authService.currentUserValue) {
      this.isAuthed = true
    }


    this.plans = {
      pro: {
        month_fee: 29.9,
        year_fee: 299,
        month_feeD: 14.9,
        year_feeD: 169,
      },
      proPlus: {
        month_fee: 99.9,
        year_fee: 999,
        month_feeD: 49.9,
        year_feeD: 599,
      },
    }

    this.plans$ = this.plansService.plansList({}).pipe(
      pluck('results'),
    )

  }

  onChangeDuration(d: any) {
    this.duration = d
  }

  onPayment() {
    this.paymenting = true
    this.paymentService.paymentRead({planCode: 'pro', duration: this.duration, discount: '50off'}).subscribe(
      value => {
        // this.router.
        // window.open('https://nowpayments.io/payment/?iid=' + value.nowpayments_invoice_id, "_blank");
        if (value.nowpayments_invoice_id) {
          window.open('https://nowpayments.io/payment/?iid=' + value.nowpayments_invoice_id, "_self");
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Khata',
          });
          this.paymenting = false
        }

      },
      error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
        });
        this.paymenting = false;
        console.log(this.paymenting)
      },
      () => {
        this.paymenting = false;
      }
    )
  }
}

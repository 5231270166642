<ng-container *ngIf="(coinInfo$ | async) as coinInfo">
  <ng-container *ngIf="(coinData$ | async) as coinData">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div class="me-7 mb-4">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img *ngIf="coinInfo.logo" [src]="coinInfo.logo" [alt]="coinInfo.name"/>
              <span *ngIf="!coinInfo.logo"
                    class="symbol-label bg-warning text-inverse-warning fw-bolder"
                    [style.font-size.rem]="5"> {{coinInfo.name | slice:0:1}}</span>
              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4
            border-white h-20px w-20px"
                   [ngClass]="{'bg-success': coinInfo.is_active, 'bg-danger': !coinInfo.is_active}"
                   [ngbTooltip]="coinInfo.is_active ? ('COIN.ACTIVE' | translate) : ('COIN.DEACTIVE' | translate) "></div>
            </div>
          </div>

          <div class="flex-grow-1">
            <div class=" d-flex justify-content-between align-items-start flex-wrap mb-2 ">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <a class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1 cursor-pointer ">
                    {{coinInfo.name}}
                  </a>
                  <!--                <a class="cursor-pointer">-->
                  <!--                <span [inlineSVG]="'./assets/media/icons/duotune/general/gen026.svg'"-->
                  <!--                      class="svg-icon svg-icon-1 svg-icon-primary"></span>-->
                  <!--                </a>-->
                  <span class="btn btn-light-dark fw-bolder ms-2 fs-8 py-1 px-3 "
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_upgrade_plan">
                  {{coinInfo.symbol}}
                </span>
                  <a class=" btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 cursor-pointer"
                     data-bs-toggle="modal"
                     data-bs-target="#kt_modal_upgrade_plan">
                    {{coinInfo.category}}
                  </a>
                </div>

                <div *ngIf="website_urls$ | async" class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 ps-0">

                  <app-urls [urls$]="website_urls$" icon_i="bi-globe"></app-urls>
                  <app-urls [urls$]="explorer_urls$" icon_i="bi-eye"></app-urls>

                  <!--                <a-->
                  <!--                  *ngFor="let wu of (website_urls$ |async)"-->
                  <!--                  class="-->
                  <!--                  d-flex-->
                  <!--                  align-items-center-->
                  <!--                  text-gray-400 text-hover-primary-->
                  <!--                  me-5 ms-0-->
                  <!--                  mb-2-->
                  <!--                  cursor-pointer-->
                  <!--                "-->
                  <!--                  [href]="wu.url"-->
                  <!--                >-->
                  <!--                  <i class="bi bi-globe me-1 ms-0"></i>-->
                  <!--                  {{wu.group}}-->
                  <!--                </a>-->

                  <a
                    class="
                  d-flex
                  align-items-center
                  text-gray-400 text-hover-primary
                  me-5 ms-0
                  mb-2
                  cursor-pointer
                "
                    [href]="'https://coinmarketcap.com/currencies/'+coinInfo.slug"
                  >
                    <i class="bi bi-info-circle me-1 ms-0"></i>
                    coinmarketcap.com
                  </a>


                </div>
              </div>

              <!--            <div class="d-flex my-4">-->
              <!--              <a-->
              <!--                class="btn btn-sm btn-light me-2 cursor-pointer"-->
              <!--                id="kt_user_follow_button"-->
              <!--              >-->
              <!--              <span-->
              <!--                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"-->
              <!--                class="svg-icon svg-icon-3 d-none"-->
              <!--              ></span>-->
              <!--                <span class="indicator-label">Follow</span>-->
              <!--                <span class="indicator-progress">-->
              <!--                Please wait...-->
              <!--                <span-->
              <!--                  class="spinner-border spinner-border-sm align-middle ms-2"-->
              <!--                ></span>-->
              <!--              </span>-->
              <!--              </a>-->
              <!--              <a-->
              <!--                class="btn btn-sm btn-primary me-3 cursor-pointer"-->
              <!--                data-bs-toggle="modal"-->
              <!--                data-bs-target="#kt_modal_offer_a_deal"-->
              <!--              >-->
              <!--                Hire Me-->
              <!--              </a>-->
              <!--              <div class="me-0">-->
              <!--                <button-->
              <!--                  class="-->
              <!--                  btn btn-sm btn-icon btn-bg-light btn-active-color-primary-->
              <!--                "-->
              <!--                  data-kt-menu-trigger="click"-->
              <!--                  data-kt-menu-placement="bottom-end"-->
              <!--                  data-kt-menu-flip="top-end"-->
              <!--                >-->
              <!--                  <i class="bi bi-three-dots fs-3"></i>-->
              <!--                </button>-->
              <!--                &lt;!&ndash;                <app-dropdown-menu1></app-dropdown-menu1>&ndash;&gt;-->
              <!--              </div>-->
              <!--            </div>-->
            </div>

            <div class="d-flex flex-wrap flex-stack">
              <div class="d-flex flex-column flex-grow-1 pe-8">
                <div class="d-flex flex-wrap">
                  <div
                    class="
                  border border-gray-300 border-dashed
                  rounded
                  min-w-125px
                  py-3
                  px-4
                  me-6
                  mb-3
                "
                  >
                    <div class="d-flex align-items-center">
                      <!--                      <span-->
                      <!--                        [inlineSVG]="-->
                      <!--                          './assets/media/icons/duotune/arrows/arr066.svg'-->
                      <!--                        "-->
                      <!--                        class="svg-icon svg-icon-3 svg-icon-success me-2"-->
                      <!--                      ></span>-->
                      <i *ngIf="coinData.ranks.current === coinData.ranks.min"
                         class="bi bi-check text-success me-2 ms-0 fs-2x"></i>
                      <i *ngIf="coinData.ranks.current === coinData.ranks.max && coinData.ranks.max>coinData.ranks.min "
                         class="bi bi-x text-danger me-2 ms-0 fs-2x"></i>
                      <div class="fs-2 fw-bolder">{{coinData.ranks.current}}</div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">{{'COIN.CURRENT_RANK' | translate}}</div>
                  </div>

                  <div
                    class="
                  border border-gray-300 border-dashed
                  rounded
                  min-w-125px
                  py-3
                  px-4
                  me-6
                  mb-3
                "
                  >
                    <div class="d-flex align-items-center">
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr066.svg'
                    "
                    class="svg-icon svg-icon-3 svg-icon-success me-2"
                  ></span>
                      <div class="fs-2 fw-bolder">{{coinData.ranks.min}}</div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">{{'COIN.MIN_RANK' | translate}}</div>
                  </div>

                  <div
                    class="
                  border border-gray-300 border-dashed
                  rounded
                  min-w-125px
                  py-3
                  px-4
                  me-6
                  mb-3
                "
                  >
                    <div class="d-flex align-items-center">
                      <span
                        [inlineSVG]="
                          './assets/media/icons/duotune/arrows/arr065.svg'
                        "
                        class="svg-icon svg-icon-3 svg-icon-danger me-2"
                      ></span>
                      <div class="fs-2 fw-bolder">{{coinData.ranks.max}}</div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-400">{{'COIN.MAX_RANK' | translate}}</div>
                  </div>

                </div>
              </div>

              <!--            <div-->
              <!--              class="-->
              <!--              d-flex-->
              <!--              align-items-center-->
              <!--              w-200px w-sm-300px-->
              <!--              flex-column-->
              <!--              mt-3-->
              <!--            "-->
              <!--            >-->
              <!--              <div class="d-flex justify-content-between w-100 mt-auto mb-2">-->
              <!--              <span class="fw-bold fs-6 text-gray-400"-->
              <!--              >Profile Compleation</span-->
              <!--              >-->
              <!--                <span class="fw-bolder fs-6">50%</span>-->
              <!--              </div>-->
              <!--              <div class="h-5px mx-3 w-100 bg-light mb-3">-->
              <!--                <div-->
              <!--                  class="bg-success rounded h-5px"-->
              <!--                  role="progressbar"-->
              <!--                  [style.width]="'50%'"-->
              <!--                ></div>-->
              <!--              </div>-->
              <!--            </div>-->
            </div>
          </div>
        </div>

        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
          nav nav-stretch nav-line-tabs nav-line-tabs-2x
          border-transparent
          fs-5
          fw-bolder
          flex-nowrap
        "
          >
            <li class="nav-item">
              <a class="nav-link text-active-primary me-6" [routerLink]="'/coins/'+coinInfo.id+'/overview'"
                 routerLinkActive="active">
                {{'COIN.OVERVIEW.TITLE' | translate}}
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-active-primary me-6" [routerLink]="'/coins/'+coinInfo.id+'/ranking'"
                 routerLinkActive="active">
                {{'COIN.RANKING.TITLE' | translate}}
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <router-outlet
      (activate)='onActivate($event)'
    ></router-outlet>
  </ng-container>
</ng-container>


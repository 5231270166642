import {Component, OnInit} from '@angular/core';
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {DashboardService} from "../../api/services/dashboard.service";
import {ActivatedRoute} from "@angular/router";
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboard$: Observable<any>

  constructor(
    private dashboardService: DashboardService,
    private generalService: GeneralService,
  ) {
    this.generalService.Title({translate: true, titleString: 'DASHBOARD.TITLE'})
  }

  ngOnInit(): void {
    this.generalService.Title({translate: true, titleString: 'DASHBOARD.TITLE'})


    this.dashboard$ = this.dashboardService
      .dashboardList()
      .pipe(
        map(value => {
          return value
        }),
        catchError((err, caught) => {
          console.log('error: ', err)
          // return caught
          return ''
        })
      )

  }

}

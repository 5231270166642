<div class="card mb-3" style="max-width: 540px;">
  <div class="row g-0">
    <div class="col-sm-4">
      <img src="assets/media/wallet/{{address}}.{{formatQR}}" class="img-fluid rounded-start" alt="...">
    </div>
    <div class="col-sm-8">
      <div class="card-header">
        <h5 class="card-title">{{name}} ({{symbol}}) in {{network}} network</h5>
      </div>
      <div class="card-body">
        <p class="card-text">{{address}}</p>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private title: Title,
    private translateService: TranslateService,
  ) {
  }

  TitlePartCreator(titlePart: TitlePart): Observable<string> {
    if (titlePart.translate) {
      return this.translateService.get(titlePart.titleString)
    } else {
      return of(titlePart.titleString)
    }
  }

  Title(TitlePart1: TitlePart,
        TitlePart2: TitlePart = {translate: false, titleString: ''}) {
    let t: string = '';
    let a1: string = '';
    let a2: string = '';

    this.TitlePartCreator({
        translate: true,
        titleString: 'GENERAL.HASHTCOIN'
      }
    ).subscribe(value => {
      t = value
    })
    this.TitlePartCreator({
        translate: TitlePart1.translate,
        titleString: TitlePart1.titleString
      }
    ).subscribe(value1 => {
      a1 = value1
    });
    this.TitlePartCreator({
        translate: TitlePart2.translate,
        titleString: TitlePart2.titleString
      }
    ).subscribe(value2 => {
      a2 = value2
    })


    if (a2 != '') {
      this.title.setTitle(t + " | " + a1 + " - " + a2);
    } else {
      this.title.setTitle(t + " | " + a1);
    }

    // this.translateService.get(titleTranslateAddress).subscribe(pageTitle => {
    //   this.translateService.get('GENERAL.HASHTCOIN').subscribe(mainTitle => {
    //     this.title.setTitle(mainTitle + " | " + pageTitle);
    //   })
    // })
  }


}


export interface TitlePart {
  translate: boolean
  titleString: string
}


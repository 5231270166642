import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {catchError, map} from "rxjs/operators";
import {DashboardService} from "../../api/services/dashboard.service";
import {ToastService} from "../../services/toast/toast.service";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "../../modules/i18n";
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  coun: number = 0;
  count_all_coins: number = 0
  count_all_rankshots: number = 0
  count_all_users: number = 0
  a: number = 9000;
  default_count_all_coins: number = 9000
  default_count_all_rankshots: number = 5
  default_count_all_users: number = 88

  heightEl = 0;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // var rect = this.counte.nativeElement.getBoundingClientRect();
    // var elemTop = rect.top;
    // var elemBottom = rect.bottom;
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // isVisible ? this.coun = this.a : null


    var rect_count_all_coins = this.el_count_all_coins.nativeElement.getBoundingClientRect();
    var elemTop_count_all_coins = rect_count_all_coins.top;
    var elemBottom_count_all_coins = rect_count_all_coins.bottom;
    var isVisible_count_all_coins = (elemTop_count_all_coins >= 0) && (elemBottom_count_all_coins <= window.innerHeight);
    isVisible_count_all_coins ? this.count_all_coins = this.default_count_all_coins : null


    var rect_count_all_rankshots = this.el_count_all_rankshots.nativeElement.getBoundingClientRect();
    var elemTop_count_all_rankshots = rect_count_all_rankshots.top;
    var elemBottom_count_all_rankshots = rect_count_all_rankshots.bottom;
    var isVisible_count_all_rankshots = (elemTop_count_all_rankshots >= 0) && (elemBottom_count_all_rankshots <= window.innerHeight);
    isVisible_count_all_rankshots ? this.count_all_rankshots = this.default_count_all_rankshots : null


    var rect_count_all_users = this.el_count_all_users.nativeElement.getBoundingClientRect();
    var elemTop_count_all_users = rect_count_all_users.top;
    var elemBottom_count_all_users = rect_count_all_users.bottom;
    var isVisible_count_all_users = (elemTop_count_all_users >= 0) && (elemBottom_count_all_users <= window.innerHeight);
    isVisible_count_all_users ? this.count_all_users = this.default_count_all_users : null


  }

  // @ViewChild('counte') counte: ElementRef;
  @ViewChild('el_count_all_coins') el_count_all_coins: ElementRef;
  @ViewChild('el_count_all_rankshots') el_count_all_rankshots: ElementRef;
  @ViewChild('el_count_all_users') el_count_all_users: ElementRef;


  constructor(
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private title: Title,
    private translateService: TranslateService,
    private translationService:TranslationService,
    private generalService:GeneralService,
  ) {
    generalService.Title({translate:true,titleString:'US.TITLE'})
  }

  ngOnInit(): void {

    this.dashboardService.dashboardList().subscribe(
      value => {
        // console.log(value)
        if (value != null) {
          // this.a = value!['count_all_coins'];
          this.default_count_all_coins = value!['count_all_coins'];
          this.default_count_all_rankshots = value!['count_all_rankshots'];
          this.default_count_all_users = value!['count_all_users'];
        }
      }
    )


  }

  onDocsReq() {
    this.toastService.show('Comming Soon')
  }

}

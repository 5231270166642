import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {CoinInfo_} from "../../../../api/models/coin-info-_";
import {CoinInfosService} from "../../../../api/services/coin-infos.service";
import {CoinURL_} from "../../../../api/models/coin-url_";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  id_coin: string | ''
  coinInfo$: Observable<CoinInfo_>
  urls$: Observable<CoinURL_[]>
  is_empty:Boolean

  constructor(
    private coinInfosService: CoinInfosService
  ) {
  }

  ngOnInit(): void {
    this.coinInfo$ = this.coinInfosService.coinInfosRead(this.id_coin)

    this.coinInfo$.subscribe(value => {
      if ((value.description == '' || value.description == null) && !value.tags ){
        this.is_empty = true
      }
    })
  }

}

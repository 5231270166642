import {Component, OnInit} from '@angular/core';
import {GeneralService} from "../../services/general/general.service";

@Component({
  selector: 'app-wallet-addresses',
  templateUrl: './wallet-addresses.component.html',
  styleUrls: ['./wallet-addresses.component.scss']
})
export class WalletAddressesComponent implements OnInit {

  constructor(
    private generalService: GeneralService,
  ) {
    generalService.Title({translate: true, titleString: 'WALLET.TITLE'})
  }

  ngOnInit(): void {
  }

}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TokenObtainPair } from '../models/token-obtain-pair';
import { TokenVerify } from '../models/token-verify';
import { ProviderAuth } from '../models/provider-auth';
@Injectable({
  providedIn: 'root',
})
class AuthService extends __BaseService {
  static readonly authJwtCreateCreatePath = '/auth/jwt/create/';
  static readonly authJwtRefreshCreatePath = '/auth/jwt/refresh/';
  static readonly authJwtVerifyCreatePath = '/auth/jwt/verify/';
  static readonly authOReadPath = '/auth/o/{provider}/';
  static readonly authOCreatePath = '/auth/o/{provider}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   * @return success!
   */
  authJwtCreateCreateResponse(data: TokenObtainPair): __Observable<__StrictHttpResponse<{access?: string, refresh?: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/jwt/create/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{access?: string, refresh?: string}>;
      })
    );
  }
  /**
   * @param data undefined
   * @return success!
   */
  authJwtCreateCreate(data: TokenObtainPair): __Observable<{access?: string, refresh?: string}> {
    return this.authJwtCreateCreateResponse(data).pipe(
      __map(_r => _r.body as {access?: string, refresh?: string})
    );
  }

  /**
   * @param data undefined
   * @return success!
   */
  authJwtRefreshCreateResponse(data: {refresh: string}): __Observable<__StrictHttpResponse<{access: string, refresh: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/jwt/refresh/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{access: string, refresh: string}>;
      })
    );
  }
  /**
   * @param data undefined
   * @return success!
   */
  authJwtRefreshCreate(data: {refresh: string}): __Observable<{access: string, refresh: string}> {
    return this.authJwtRefreshCreateResponse(data).pipe(
      __map(_r => _r.body as {access: string, refresh: string})
    );
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   * @param data undefined
   */
  authJwtVerifyCreateResponse(data: TokenVerify): __Observable<__StrictHttpResponse<TokenVerify>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/jwt/verify/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenVerify>;
      })
    );
  }
  /**
   * Takes a token and indicates if it is valid.  This view provides no
   * information about a token's fitness for a particular use.
   * @param data undefined
   */
  authJwtVerifyCreate(data: TokenVerify): __Observable<TokenVerify> {
    return this.authJwtVerifyCreateResponse(data).pipe(
      __map(_r => _r.body as TokenVerify)
    );
  }

  /**
   * @param provider undefined
   */
  authOReadResponse(provider: string): __Observable<__StrictHttpResponse<ProviderAuth>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/auth/o/${encodeURIComponent(String(provider))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProviderAuth>;
      })
    );
  }
  /**
   * @param provider undefined
   */
  authORead(provider: string): __Observable<ProviderAuth> {
    return this.authOReadResponse(provider).pipe(
      __map(_r => _r.body as ProviderAuth)
    );
  }

  /**
   * @param params The `AuthService.AuthOCreateParams` containing the following parameters:
   *
   * - `provider`:
   *
   * - `data`:
   */
  authOCreateResponse(params: AuthService.AuthOCreateParams): __Observable<__StrictHttpResponse<ProviderAuth>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/auth/o/${encodeURIComponent(String(params.provider))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProviderAuth>;
      })
    );
  }
  /**
   * @param params The `AuthService.AuthOCreateParams` containing the following parameters:
   *
   * - `provider`:
   *
   * - `data`:
   */
  authOCreate(params: AuthService.AuthOCreateParams): __Observable<ProviderAuth> {
    return this.authOCreateResponse(params).pipe(
      __map(_r => _r.body as ProviderAuth)
    );
  }
}

module AuthService {

  /**
   * Parameters for authOCreate
   */
  export interface AuthOCreateParams {
    provider: string;
    data: ProviderAuth;
  }
}

export { AuthService }
